import styled from "styled-components"

export const ForgotInputGroup = styled.div`
display: flex;
flex-direction: column;
`;

export const ForgotLabel = styled.label`
font-weight: 400;
font-size: 1rem;
line-height: 20px;
margin-bottom: .5rem;
color: #000000;
`;

export const ForgotInputs = styled.input`
width: 100%;
height: 50px;
background: #FFFFFF;
font-size: .9rem;
outline: none;
padding: 0 1.5rem;
border: 1.8px solid #dcdbdb !important;
border-radius: 10px;
box-sizing: border-box;
`;

export const ErrorMessage = styled.span`
color: red;
font-size: .8rem;
margin-top: .4rem;
`;