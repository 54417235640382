import { NavigateFunction } from 'react-router-dom'
import { BreadCrumbsType } from './Type'


export const getUsersListBreadCrumbs = (navigate: NavigateFunction,id: String): Array<BreadCrumbsType> => [
    {
        text: "Dashboard", href: `/admin/${id}`, onClick: () => {
            navigate(`/admin/${id}`)
        }
    },
    
    { text: "Users List" },
]; 

export const getCreateSubBreadCrumbs = (navigate: NavigateFunction, id: string): Array<BreadCrumbsType> => [
    {
        text: "Dashboard", href: "#", onClick: () => {
            navigate(`/admin/${id}`)
        }
    },
    
    { text: "Create User Subcription"},
]; 

export const getManangeBreadCrumbs = (navigate: NavigateFunction, id: string): Array<BreadCrumbsType> => [
    {
        text: "Dashboard", href: "#", onClick: () => {
            navigate(`/admin/${id}`);
        }
    },
    
    { text: "Manage users subcription" },
]; 
