import React, { useEffect, useState } from "react";
import axios from "axios";

const userfetchUsers = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [users, setUsers] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem('token'); 

      if (!token) {
        console.error("Error fetching users:", "No token");
        return;
      }

      try {
        const response = await axios.get('https://sinogani-api-server.onrender.com/users');
        setUsers(response.data);
      } catch (error) {
        console.error("Failed to fetch users:", "No token");
      } 
    };

    fetchUsers();
  }, []); // Empty dependency array to run the effect only once

  return [users];
};

export default userfetchUsers;
