import styled from "styled-components";

export const ProductDetailGlobalContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
margin-bottom: 50px;
text-decoration: none;
`

export const ProductDetailContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
overflow: hidden;
text-decoration: none;
`;

export const RelatedProductContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
overflow: hidden;
text-decoration: none;
margin-top: 50px;
`;

export const ProductDetailLeftContainer = styled.div`
display: flex;
flex: 1;
flex-direction: column;
`;

export const ProductDetailRightContainer = styled.div`
display: flex;
flex: 1;
flex-direction: column;
`;

export const ProductDetailNameText = styled.h1`
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 29px;
text-transform: uppercase;
color: #000000;
margin-bottom: 25px;
`;

export const ProductDetailPriceText = styled.div`
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 27px;
text-transform: uppercase;
color: #000000;
margin-bottom: 25px;
`;

export const ProductDetailText = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
text-transform: uppercase;
color: #000000;
margin-bottom: 50px;
`;

export const ProductDetailTexts = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
text-transform: uppercase;
color: #000000;
margin-bottom: 1rem;
`;

export const ProductDetailAddCartContainers = styled.div`
width: 100%;
display: flex;
flex-direction: row;
overflow: hidden;
margin-bottom: 35px;
`;

export const ProductDetailQuantityContainer = styled.div`
min-width: 90px;
height: 40px;
display: flex;
flex-direction: row;
border: 1px solid #000000 !important;
border-radius: 100px;
justify-content: center;
align-items: center;
margin-right: 20px;
`;

export const ProductSizeContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;

export const ProductSizesContentContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
margin-bottom: 3.5rem;
`;

export const ProductDetailQuantityOption = styled.div`
flex: 1;
cursor: pointer;
font-style: normal;
font-weight: 400;
font-size: 22px;
height: 100%;
align-items: center;
justify-content: center;
display: flex;
user-select: none !important;
line-height: 27px;
text-transform: uppercase;
color: #000000;
`;

export const ProductDetailQuantyText = styled.p`
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 27px;
user-select: none;
text-transform: uppercase;
color: #000000;
`;


export const ProductDetailAddCartContainer = styled.div`
width: 150px;
height: 40px;
cursor: pointer;
box-sizing: border-box;
border: 1px solid #000000 !important;
display: flex;
justify-content: center;
align-items: center;
border-radius: 100px;
font-style: normal;
font-weight: 500;
font-size: 11px;
line-height: 13px;
text-transform: uppercase;
color: #000000;
`;

export const ProductDetailFavorisContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
margin-bottom: 73px;
`;

export const ProductDetailFavorisImg = styled.img`
width: 30px;
height: 30px;
`

export const ProductDetailLikeContainer = styled.div`
width: 52px;
height: 52px;
border-radius: 50%;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
margin-right: 10px;
opacity: .8;

&:hover{
    background-color: #f5e7e7;
}
`;

export const ProductDetailProgressContainer = styled.div`
width: 30px;
height: 30px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
margin-right: 1rem;
`;

export const ProductDetailFavorisText = styled.h4`
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #000000;
`

export const ProductHeadLeftPathText = styled.h5`
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 15px;
text-transform: capitalize;
color: #000000;
margin-bottom: 10px;
`;

export const ProductImgContainer = styled.div`
width: 500px;
height: 500px;
background-color: #fff;
display: flex;
position: relative;
justify-content: center;
flex-direction: column;
overflow: hidden;
`;

export const ProductFinanceText = styled.h6`
font-style: normal;
font-weight: 200;
font-size: 12px;
line-height: 15px;
color: #000000;
z-index: 1;
position: absolute;
top: 35px;
left: 38px;
`;

export const ProductDetailImgSelect = styled.img`
max-width: 300px;
max-height: 300px;
align-self: center;
justify-self: center;
object-fit: cover;
z-index: .5;
margin-top: 1.5rem;
margin-bottom: 1rem;
border: none;
`;

export const ProductDetailVideoSelect = styled.video`
width: 100%;
max-height: 350px;
align-self: center;
justify-self: center;
object-fit: cover;
z-index: .5;
margin-top: 0rem;
margin-bottom: 1rem;
border: none;
`;

export const ProductImgsContainer = styled.div`
display: flex;
align-items: center;
width: 500px;
height: 120px;
justify-content: center;
overflow-x: hidden;
margin-top: auto;
margin-bottom: 38px;
`;

export const ProductDetailImgContainer = styled.div`
width: 90px;
height: 90px;
display: flex;
align-items: center;
justify-content: center;
//background: #F6F6F6;
cursor: pointer;
border: solid 1px #F6F6F6 !important;


&:hover{
    border: solid 2px #D9D9D9 !important;
}
`;

export const ProductVideoThumbailContainer = styled.div`
width: 90px;
height: 90px;
display: flex;
overflow: hidden;
align-items: center;
justify-content: center;
//background: #F6F6F6;
flex-direction: column;
cursor: pointer;
margin-left: 12.5px;
margin-right: 12.5px;
border: solid 1px #F6F6F6 !important;


&:hover{
    border: solid 2px #D9D9D9 !important;
}
`;

export const ProductVideoPlayIcon = styled.img`
width: 20px;
height: 20px;
position: fixed;
margin-left: 3.5rem;
margin-top: 2rem;
margin-bottom: 3.5rem;
margin-right:2rem;
z-index: 1;
filter: invert(100%) sepia(91%) saturate(38%) hue-rotate(321deg) brightness(110%) contrast(110%);
`;

export const ProductDetailImg = styled.img`
width: 100%;
height: 100%;
object-fit: cover !important;
border: none !important;
`;


export const ProductContactWhatContainer = styled.div`
height: 50px;
width: 35.75rem;
cursor: pointer;
background: #000000;
border-radius: 100px;
display: flex;
`


