import React from 'react'
import { ForgotInputGroup, ForgotLabel  , ForgotInputs, ErrorMessage} from './ForgotPasswordInput.element';

const ForgotPasswordInput = ({id, label , type , register , errorMessage}) => {

    const registerInput = <ForgotInputs placeholder='Enter your email' id={id} type={type} {...register}/>;

  return (
    <ForgotInputGroup>
        <ForgotLabel htmlFor={id}>{label}</ForgotLabel>
        {registerInput}
        <ErrorMessage>{errorMessage}</ErrorMessage>
    </ForgotInputGroup>
  )
}

export default ForgotPasswordInput
