import React from 'react'
import { ErrorSUMessage, RegisterSUInputContainer, RegisterSUInputGroup, RegisterSUInputs, RegisterSUISnputGroup } from './RegisterSUImput.element'

const RegisterSUImput = ({id, type , register , errorMessage, group, placeholder}) => {
  
    let registerInput;

    if (id === 'email'){
        registerInput = (<RegisterSUInputs id={id} type={type} placeholder='Email' {...register}/>);
    }else if (id === 'password'){
        registerInput = (<RegisterSUInputs id={id} placeholder='Password' type={type} {...register}/>);
    }else {
        registerInput = (<RegisterSUInputs id={id} placeholder={placeholder} type={type} {...register}/>);
    }

  return (
    <>
      {
        group === false ? (
            
            <RegisterSUInputGroup>
                <RegisterSUInputContainer>
                    {registerInput}
                 </RegisterSUInputContainer>
                 <ErrorSUMessage>{errorMessage}</ErrorSUMessage>
            </RegisterSUInputGroup>

        )
        
        : 
        
        (
            <RegisterSUISnputGroup>
                <RegisterSUInputContainer>
                    {registerInput}
                 </RegisterSUInputContainer>
                 <ErrorSUMessage>{errorMessage}</ErrorSUMessage>
            </RegisterSUISnputGroup>

        )
    }
    </>
  )


}

export default RegisterSUImput
