import React from 'react'
import { CountryContainer, CountryContentContainer, CountryFlagImg, CountryFlagName, CountryIndicatorImg, CountryLinkWrapper } from './Country.element';

const Country = ({flag, countryName, id, africa, devise}) => {

    const countryFlags = require('../images/flags/'+flag);
    const icon = require('../images/icon.png');
    const link = africa ? `/africa-space/${id}?q=${id}&d=${devise}` : `/china-space/${id}?q=${id}&d=${devise}`;

  return (
    <CountryLinkWrapper to={link}>
      <CountryContainer>
        <CountryContentContainer>
            <CountryFlagImg src={countryFlags} loading='lazy' alt='Country Flags' />
            <CountryFlagName>{countryName}</CountryFlagName>
            <CountryIndicatorImg src={icon} alt='Flag Indicator' />
        </CountryContentContainer>
      </CountryContainer>
    </CountryLinkWrapper>
  )
}

export default Country
