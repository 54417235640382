import React from 'react'
import { DdsSizeWrapper, DdsSizeContent, DdsSizeSearch, DdsSizeIcons, DdsSearchSizeInput, DdsSizeOptions, DdsSizeList  } from './DropdownSelectSizeMenuSearch.element';

const DropdownSelectSizeMenuSearch = ({ data, changeCategoryState, changeActiveState, isActive }) => {

    const wrapperRef = React.useRef(null);
    

    const [filterVal , setFilterVal] = React.useState('');
    const [filtered, setFiltered] = React.useState([]);
    const [searchApiData , setSearchApiData] = React.useState([]);
  

    const handleFilter = (e) => {
        if(e.target.value === ''){
          setFiltered(searchApiData)
        }else{
          const filterResult = searchApiData.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
          setFiltered(filterResult);
        }
  
        setFilterVal(e.target.value);
      }
    
    const handleOnclickSelectBtn = (item) => {

        changeCategoryState(item.type);
        changeActiveState();

    }


    const compareByName = (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
      
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      };

    React.useEffect(() => {
        setFiltered(data);
        setSearchApiData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
      if(wrapperRef.current){
          wrapperRef.current.classList.toggle('active');
        }
    }, [isActive]);

    React.useEffect(() => {
      if(wrapperRef.current){
        wrapperRef.current.classList.toggle('active');
      }
    }, [])
 
   

  return (
    <DdsSizeWrapper ref={wrapperRef}>
        <DdsSizeContent>
            <DdsSizeSearch>
                <DdsSizeIcons className='uil uil-search'/>
                <DdsSearchSizeInput type='text' value={filterVal} placeholder='Search' onInput={(e) => handleFilter(e)} />
            </DdsSizeSearch>

            <DdsSizeOptions>
                {
                    [...filtered].sort(compareByName).map((item, index) => <DdsSizeList onClick={() => handleOnclickSelectBtn(item)} key={index}>{item.name}</DdsSizeList>)
                }
            </DdsSizeOptions>
        </DdsSizeContent>
    </DdsSizeWrapper>
  )

}

export default DropdownSelectSizeMenuSearch;
