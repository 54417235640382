import React, { useRef } from 'react'
import { DdsContent, DdsIcon, DdsIcons, DdsList, DdsOptions, DdsSearch, DdsSearchInput, DdsSelectBtn, DdsText, DdsWrapper, DropdownLabel } from './DropdownSelectMenuWithSearch.element'

const DropdownSelectMenuWithSearch = ({ data, label, selectType , isSearch, changeCategoryState, changeCountrySate, isLabel, category }) => {

    const wrapperRef = useRef(null);
    const selectBtn = useRef(null);

    const [filterVal , setFilterVal] = React.useState('');
    const [filtered, setFiltered] = React.useState([]);
    const [searchApiData , setSearchApiData] = React.useState([]);
    const [nameSelected, setNameSelected] = React.useState(category);

    const handleFilter = (e) => {
        if(e.target.value === ''){
          setFiltered(searchApiData)
        }else{
          const filterResult = searchApiData.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
          setFiltered(filterResult);
        }
  
        setFilterVal(e.target.value);
      }
    
    const handleOnclickSelectBtn = () => {
        
        if(wrapperRef.current){
            wrapperRef.current.classList.toggle('active');
        }

    }

    const compareByName = (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
      
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      };

    React.useEffect(() => {
        setFiltered(data);
        setSearchApiData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

  return (
    <DdsWrapper mg={category === null} ref={wrapperRef}>
        {isLabel && <DropdownLabel>{label}</DropdownLabel>}
        <DdsSelectBtn ref={selectBtn} onClick={handleOnclickSelectBtn}>
            <DdsText>{nameSelected ? nameSelected : selectType}</DdsText>
            <DdsIcon className='uil uil-angle-down' />
        </DdsSelectBtn>

        <DdsContent>
            {
                isSearch && 
                <DdsSearch>
                    <DdsIcons className='uil uil-search'/>
                    <DdsSearchInput type='text' value={filterVal} placeholder='Search' onInput={(e) => handleFilter(e)} />
                </DdsSearch>
            }

            <DdsOptions>
                {
                    [...filtered].sort(compareByName).map((item, index) => <DdsList onClick={() => {
                        setNameSelected(item.name);
                        isSearch ? changeCountrySate(item.id) : changeCategoryState(item.type);
                    }} key={index}>{item.name}</DdsList>)
                }
            </DdsOptions>
        </DdsContent>
    </DdsWrapper>
  )
}

export default DropdownSelectMenuWithSearch;
