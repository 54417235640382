import { configureStore } from "@reduxjs/toolkit";
import { subscriptionSlice } from "./slices/UserSubSlice";
import cartSlice from "./shopping-cart/cartSlice";

export const store = configureStore({
  reducer: {
    cart: cartSlice.reducer,
    subscription: subscriptionSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;