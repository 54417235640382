import axios from 'axios';

const useDeleteProduct = () => {
    const deleteProduct = async (productId, images, videoUrl) => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.delete(`https://sinogani-api-server.onrender.com/api/products/${productId}`, {
                data: { images, videoUrl },
            },
            {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
          );
          return response.data;
        } catch (error) {
          console.error('Erreur lors de la suppression du produit:', error);
          throw error;
        }
      };
    
 return { deleteProduct };
}

export default useDeleteProduct;