import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/system';
import GlobalStyle from './globalStyles';
import { ThemeProvider } from '@mui/material/styles';
import { AdminPage, SignInPage, UserProfilePage, UserListPage, CreateSubType, ManageUserSub, CountrySpace, CountryProduct, AddProduct, ProductListPage, Cart } from './components';
import { EuiGlobalToastList, EuiProvider, EuiThemeProvider} from '@elastic/eui';
import ThemeSelector from './components/ThemeSelector';
import { useDispatch } from "react-redux";
import { useAppSelector } from './stores/hooks';
import { setToasts } from './stores/slices/UserSubSlice';

import theme from './Themes/theme';

const App =  () => {
  const dispatch = useDispatch();
  const toasts = useAppSelector((zoom) => zoom.subscription.toasts);
  

  const overrides = {
    colors: {
      LIGHT: { primary: "#0b5cff" },
      DARK: { primary: "#0b5cff" },
    },
  };

  const removeToast = (removeToast) => {
    dispatch(
      setToasts(
        toasts.filter((toast) => toast.id !== removeToast.id)
      )
    );
  };

  return (  
      
    <BrowserRouter>

     <Box sx={{backgroundColor: '#d5dbe1'}}>

        <GlobalStyle/>

        <ThemeProvider theme={theme}>
            <ThemeSelector>
                <EuiProvider colorMode={'light'}>

                    <EuiThemeProvider modify={overrides}>

                        <Routes>

                            <Route path='/signin' exact element={ <SignInPage /> } />
                            <Route path='/africa-space' exact element={ <CountrySpace isAfrica={true}  /> } />
                            <Route path='/china-space' exact element={ <CountrySpace isAfrica={false} /> } />
                            <Route path='/cart' exact element={ <Cart isAfrica={false} /> } />
                            <Route path='/africa-space/:country' exact element={ <CountryProduct isAfrica={true} /> } />
                            <Route path='/china-space/:province' exact element={ <CountryProduct isAfrica={false} /> } />
                            <Route path='/user/:userId' exact element={ <UserProfilePage /> } />
                            <Route path='/user/:userId/add-product' exact element={ <AddProduct  /> } />
                            <Route path='/user/:userId/product-list' exact element={ <ProductListPage /> } />
                            <Route path='/admin/:adminId' exact element={ <AdminPage /> } />
                            <Route path='/userslist' exact element={ <UserListPage /> } />
                            <Route path='/createsub' exact element={ <CreateSubType /> } />
                            <Route path='/manageusersub' exact element={ <ManageUserSub /> } />
                            <Route path="/" element={<SignInPage />} />
                            <Route path="*" element={<SignInPage  />} />

                      </Routes>

                      <EuiGlobalToastList
                        toasts={toasts}
                        dismissToast={removeToast}
                        toastLifeTimeMs={5000}/>
              
                  </EuiThemeProvider>

              </EuiProvider>
          </ThemeSelector>
        </ThemeProvider>

    </Box>

</BrowserRouter>

  );
}



export default App;
