import React from 'react'
import Header from '../Header';
import {
  EuiBadge,
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
} from "@elastic/eui";
import useAuths from '../../hooks/useAuths';
import moment from 'moment';
import axios from 'axios';


const UserListPage = () => {
  useAuths();

  const [users, setUsers] = React.useState([]);
  
  const getUserList = async () => {

    let data;

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://sinogani-api-server.onrender.com/admin/users', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      data = response.data;
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }

    return data;

  }


  const columns = [
    {
      field: "userName",
      name: "User Name",
    },
    {
      field: "email",
      name: "User Email",
    },
    {
      field: "createDate",
      name: "Date Created",
    },
    {
      field: "subType",
      name: "Subcription Type",
    },
    {
      field: "subBeginDate",
      name: "Subcription Begin Date",
    },
    {
      field: "subEndStatusDate",
      name: "Subcription End Date",
    },
    {
      field: "",
      name: "Subscription Status",
      render: (user) => {
        
          if (user.sub){
            if  (!user.cancel) {

              if (user.subStatus === true && moment(user.subBeginDate).isAfter(moment().format("L"))) {
                  return <EuiBadge color="primary">Upcoming</EuiBadge>
              }else if (user.subStatus === true && moment(user.subEndStatusDate).isAfter(moment().format("L"))){
                  return <EuiBadge color="success">Active</EuiBadge>
              }else {
                  return <EuiBadge color="default">Ended</EuiBadge>;
              }

            }else {
              return <EuiBadge color="danger">Cancelled</EuiBadge>;
            }
          }else return <EuiBadge color="default">Off</EuiBadge>;
      },
    },
  
  ];

  React.useEffect(() => {
      getUserList();
  // eslint-disable-next-line react-hooks/exhaustive-deps     
  }, []);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: "#F7F8FC",
      }}
    >
      <Header />
      <EuiFlexGroup justifyContent="center" style={{ margin: "1rem" }}>
        <EuiFlexItem>
          <EuiPanel>
           <EuiBasicTable items={users} columns={columns} />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}

export default UserListPage;
