import React from 'react';
import { EuiDatePicker, EuiFormRow } from '@elastic/eui';

const MeetingDateField = ({ selected, setStartDate }) => {
  return (
    <EuiFormRow>
      <EuiDatePicker selected={selected} onChange={(date) => setStartDate(date)} />
    </EuiFormRow>
  );
};

export default MeetingDateField;