import React from 'react'
import { PaginationContainer, PaginationNextButton, PaginationPageIndicatorContainer, PaginationPageNumber, PaginationPreviousButton } from './Pagination.element';

const Pagination = ({ totalItems, itemsPerPage, onPageChange }) => {

    const [currentPage, setCurrentPage] = React.useState(1);

    const totalPages = Math.ceil(totalItems / itemsPerPage);

     
    const handlePageChange = (page) => {
        setCurrentPage(page);
        onPageChange(page);
    };

  return (
    <PaginationContainer>
        <PaginationPreviousButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</PaginationPreviousButton>

        <PaginationPageIndicatorContainer>
            {
                Array.from({ length: totalPages }, (_, index) => (
                    <PaginationPageNumber key={index} onClick={() => handlePageChange(index + 1)}
                    className={currentPage === index + 1 ? 'active__pagination' : '' }>{ index + 1 }</PaginationPageNumber>
                ))
            }
        </PaginationPageIndicatorContainer>

        <PaginationNextButton onClick={() => handlePageChange(currentPage + 1)}
        disabled={ currentPage === totalPages }>Next</PaginationNextButton>

    </PaginationContainer>
  )
}

export default Pagination
