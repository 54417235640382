import { createSlice } from "@reduxjs/toolkit";
import { ToastType } from "../../utils/Type";

interface subscriptionInitialState {
    toasts: Array<ToastType>;
}

const initialState: subscriptionInitialState = {
    toasts: [],
};

export const subscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {
        setToasts: (state, action) => {
            state.toasts = action.payload;
        }
    },
});

export const { setToasts } = subscriptionSlice.actions;