import React, { Suspense } from "react";

const LightTheme = React.lazy(() => import("../Themes/LightTheme"));

const ThemeSelector = ({ children }) => {

  return (
    <>
      <Suspense fallback={<></>}>
        <LightTheme />
      </Suspense>
      {children}
    </>
  );
};

export default ThemeSelector;