import React from 'react'
import { ColorsChoosing, ColorsChoosingContainer, CheckColorContainer, CheckColorSub, CheckColorImage  } from './ColorChoosing.element'

const ColorChoosing = ({ bg, onDelete }) => {

const icon = require('../images/close.png');

  return (
    <ColorsChoosingContainer>
        <ColorsChoosing style={{ backgroundColor: bg }}/>
        <CheckColorContainer>
          <CheckColorSub onClick={onDelete}>
            <CheckColorImage src={icon} />
         </CheckColorSub>
        </CheckColorContainer>
    </ColorsChoosingContainer>
  )
}

export default ColorChoosing;
