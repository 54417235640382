import styled from "styled-components";
import {CgSpinner} from 'react-icons/cg';

export const RegisterContainer = styled.div`
width: '100vw';
height: '100vh';
display: flex;
flex-direction: row;
overflow-x: hidden;
justify-content: center;
background-color: #D9D9D9;
z-index: 8001;
overflow-y: auto;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
`;

export const RegisterContentContainer = styled.div`
display: inline-block;
height: auto;
margin-top: 200px;
flex-direction: column;
margin-bottom: 55px;
`;

export const RegisterText = styled.h4`
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 34px;
margin-bottom: 50px;
color: #000000;
`

export const RegisterProductImageContainer = styled.div`
width: 100%;
height:100% ;
`


export const RegisterHeadContainer = styled.div`
display: flex;
flex-direction: row;
margin-bottom: 65px;
`

export const LogoRegister = styled.img`
width: 90px;
height: 90px;
margin-right: 46px;
`;


export const RegisterHeadText = styled.p`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
display: flex;
align-self: center;
color: #000000;
`

export const RegisterInfoText = styled.p`
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;

color: #000000;
`;

export const FormRegisterContainer = styled.form`
margin-top: 100px;
`

export const ButtonRegister = styled.button`
width: 400px;
height: 50px;
margin-top: 70px;
left: 664px;
top: 1761px;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 29px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
color: #FFFFFF;
background: #000000;
border: 1px solid #000000 !important;
border-radius: 10px;
box-sizing: border-box;
`

export const RegisterSpinner = styled(CgSpinner)`
animation: spin 1s infinite linear;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`

export const RegisterSpinnerContainer = styled.span`
margin-right: .5rem;
height: 100%;
display: none;
flex-direction: column;
justify-content: center;
`;

export const RegisterBottomSpacing = styled.div`
width: 100%;
margin-bottom: 50px;
`;

/* ---------------------------------------------------------------------- */

export const FormAddProductContainer = styled.form`
margin-top: 50px;
background-color: #f8f8f8;
`

export const AddProductContainer = styled.div`
width: '100vw';
height: '100vh';
display: flex;
flex-direction: row;
overflow-x: hidden;
justify-content: center;
background-color: #f8f8f8;
z-index: 8001;
overflow-y: auto;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
`;

export const AddProductContentContainer = styled.div`
width: 1184px;
display: flex;
height: auto;
flex-direction: column;
`;

export const AddProductHeadContentContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
margin-bottom: 2rem;
justify-content: center;
`;

export const AddProductHeadRightContainer = styled.div`
display: flex;
flex: 1;
height: 100%;
`;

export const AddProductHeadLeftContainer = styled.div`
display: flex;
flex-direction: column;
flex: 1;
`;

export const AddProductTitle = styled.h1`
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 34px;
margin-bottom: 8px;
color: #000000;
`;

export const AddProductText = styled.h4`
font-weight: 400;
font-size: 1.2rem;
line-height: 20px;
color: #000000;
`;

export const AddProductButton = styled.div`
height: 40px;
width: 180px;
color: #fff;
font-size: 1rem;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin-bottom: auto;
margin-top: auto;
border-radius: 10px;
margin-left: auto;
cursor: pointer !important;
font-weight: 400;
background-color: #f55653;
`;

export const UploadDataIcon = styled.img`
width: 20px;
height: 20px;
margin-right: .5rem;
filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(62deg) brightness(103%) contrast(101%);
`;

export const AddProductSpinnerContainer = styled.span`
flex-direction: column;
display: none;
justify-content: center;
margin-right: .5rem;
`;

export const RadioButtonContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
`;

export const Form = styled.form`
display: flex;
flex-direction: row;`;

export const Label = styled.label`
  display: flex;
  cursor: pointer;
  font-weight: 500;
  margin-right: 10px;
`;

export const Span = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 10px 20px 10px 10px;
  border-radius: 31px;
  transition: 0.25s ease;

  &:hover,
  input[type="radio"]:checked + & {
    background-color: #d6d6e5;
  }
`;

export const SpanBefore = styled.span`
  content: "";
  background-color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 2px #00005c;
`;

export const CheckedSpanBefore = styled(SpanBefore)`
  box-shadow: inset 0 0 0 10px #00005c;
`;

export const AddIcons = styled.i`
color: #2f3a4e;
font-size: 24px;
`;

export const AddTexts = styled.span`
font-weight: 600;
font-size: .8rem;
line-height: 20px;
color: #2f3a4e;
`;

export const ChooseImageContainers = styled.label`
width: 90px;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
cursor: pointer;
align-items: center;
border: 2px dashed #edeff3!important;
background-color: #fff;
border-radius: .8rem;
`;



export const ChooseColorContainers = styled.label`
width: 50px;
height: 50px;
display: flex;
flex-direction: column;
justify-content: center;
cursor: pointer;
align-items: center;
border: 2px dashed #edeff3!important;
background-color: #fff;
border-radius: .5rem;
`;

export const StyledRadioInput = styled.input`
  display: none;
`;

export const StyledCheckedRadioInput = styled(StyledRadioInput)`
  &:checked + ${SpanBefore} {
    ${CheckedSpanBefore}
  }
`;

export const ChooseImageInput = styled.input`
width: 90px;
height: 100%;
`;

export const StyledLabel = styled(Label)`
  ${StyledCheckedRadioInput}:checked + & ${Span} {
    background-color: #d6d6e5;
  }
`;

export const DivSpacer = styled.div`
width: 100%;
height: 40px; 
`;

export const StyledForm = styled(Form)`
  ${StyledLabel} {
    ${SpanBefore}, ${Span} {
      transition: 0.25s ease;
    }
  }
`;

export const RadioButtonText = styled.span`
 display: inline-flex;
  align-items: center;
  padding: 10px 20px 10px 10px;
  border-radius: 31px;
  transition: 0.25s ease;

  &:hover,
  input[type="radio"]:checked + & {
    background-color: #d6d6e5;
  }
`;


export const AddProductSpinner = styled(CgSpinner)`
animation: spin 1s infinite linear;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`;

export const ProductImageAndBasicInfoContainer = styled.div`
display: flex;
width: 100%;
height: 500px;
margin-bottom: 1rem;
flex-direction: row;
`;


export const ProductBasicInformationContainer = styled.div`
display: flex;
flex-direction: column;
width: 50%;
background-color: #fff;
border: 2px solid #edeff3!important;
height: 100%;
padding: 2rem;
border-radius: 1rem;
margin-right: 1rem;
`;

export const ProductSpaceText = styled.span`
font-weight: 400;
font-size: 1rem;
line-height: 20px;
margin-bottom: 10px;
margin-top: 5px;
color: #000000;
`;

export const ProductBasicInfoTextContainer = styled.div`
width: 100%;
display: flex;
height: 50px;
align-items: center;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 34px;
color: #000000;
`;

export const SelectProductLabel = styled.span`
font-weight: 400;
font-size: 1rem;
line-height: 20px;
margin: 1rem 0;
color: #000000;
`;

export const AddProductColorContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
overflow-x: auto;
`;

export const ProductBasicFormContainer = styled.div`
width: 100%;
margin-top: 1rem;
height: 100%;
border: 1.5px solid #edeff3!important;
display: flex;
border-radius: .8rem;
flex-direction: column;
padding: 1rem;
`;

export const ProductImageContentContainer = styled.div`
width: 100%;
margin-top: 1rem;
height: 120px;
max-width: 100%;
border: 1.5px solid #edeff3!important;
display: flex;
border-radius: .8rem;
flex-direction: row;
overflow-x: auto;
white-space: nowrap;
padding: 1.2rem;
box-sizing: border-box;
`;

export const ProductImageANdCategoryContainer = styled.div`
display: flex;
flex-direction: column;
width: 50%;
height: 100%;
`;

export const ProductImageContainer = styled.div`
width: 100%;
flex-direction: column;
padding: 2rem;
height: 50%;
overflow: hidden;
margin-bottom: 1rem;
box-sizing: border-box;
border-radius: 1rem;
background-color: #fff;
border: 2px solid #edeff3!important;
`;

export const ProductCategoryContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 50%;
background-color: #fff;
border-radius: 1rem;
padding: 1.5rem;
border: 2px solid #edeff3!important;
`;

export const ProductPriceAndProductDateContainer = styled.div`
display: flex;
width: 100%;
flex-direction: row;
height: 380px;
`;

export const ProductProductDateContainer = styled.div`
display: flex;
flex-direction: column;
width: 50%;
padding: 1.5rem;
background-color: #fff;
border-radius: 1rem;
border: 2px solid #edeff3!important;
`;

export const ProductPriceContainer = styled.div`
display: flex;
flex-direction: column;
width: 50%;
background-color: #fff;
border-radius: 1rem;
padding: 1.5rem;
border: 2px solid #edeff3!important;
margin-right: 1rem;
`;

export const ProductColorSizeAndVideoContainer = styled.div`
width: 100%;
height: 380px;
display: flex;
margin-top: 1rem;
flex-direction: row;
`;

export const ProductPriceContainers = styled.div`
display: flex;
flex-direction: column;
width: 50%;
background-color: #fff;
border-radius: 1rem;
padding: 1.5rem;
border: 2px solid #edeff3!important;
margin-right: 1rem;
`;


export const ProductSizeColorContainer = styled.div`
display: flex;
flex-direction: column;
width: 50%;
background-color: #fff;
border-radius: 1rem;
padding: 1.5rem;
border: 2px solid #edeff3!important;
margin-right: 1rem;
`;

export const ProducVideoContainer = styled.div`
display: flex;
flex-direction: column;
width: 50%;
padding: 1.5rem;
background-color: #fff;
border-radius: 1rem;
border: 2px solid #edeff3!important;
`;

export const ChooseVideoContainers = styled.label`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
margin-top: 2rem;
margin-bottom: 1rem;
justify-content: center;
cursor: pointer;
align-items: center;
border: 2px dashed #edeff3!important;
background-color: #fff;
border-radius: .8rem;
`;

export const ChooseIcons = styled.i`
color: #2f3a4e;
font-size: 48px;
margin-bottom: 1rem;
`;

export const CloseIcon = styled.img`
width: 20px;
height: 20px;
margin-left: auto;
top: 0;
margin-top: 1rem;
margin-right: 1rem;
`;

export const ChooseIcon = styled.img`
color: #2f3a4e;
width: 90px;
height: 90px;
margin-bottom: 1rem;
`;

export const ChooseIconContainer = styled.div`
width: 100%;
display: flex;
height: 100%;
flex-direction: column;
align-items: center;
justify-content: center;
`

export const ChooseContainer = styled.div`
width: 100%;
height: 100%;
align-items: center;
`;

export const ChooseTexts = styled.span`
font-weight: 600;
font-size: 1.5rem;
line-height: 20px;
color: #2f3a4e;
`;

export const ChooseText = styled.p`
font-weight: 500;
font-size: .9rem;
line-height: 20px;
overflow: hidden;
text-align: center;
width: 200px;
color: #2f3a4e;
`;
