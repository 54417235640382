import axios from "axios";

const useUpdateProduct = () => {

    const updateProducts = async (product, newData) => {
  
      const token = localStorage.getItem("token");
  
      const updatedProduct = {
        productName: product.productName,
        description: product.description,
        price: newData.price ? newData.price : product.price,
        minOrder: product.minOrder,
        contact: product.contact,
        category: newData.category ? newData.category : product.category,
        dateCreated: product.dateCreated,
        images: product.images,
        deleveryDate: '2 months',
        space: product.space,
        country: product.country,
        sellerUid: product.sellerUid,
        sellerMail: product.sellerMail,
        videoUrl: product.videoUrl,
        sizes: product.sizes,
        colors: product.colors,
        stock: newData.stock ? newData.stock : product.stock,
      };
  
      try {
        await axios.put(
          'https://sinogani-api-server.onrender.com/api/update/product',
          {
            productUid: product.uid,
            newData: updatedProduct,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      } catch (error) {
        console.error('Error updating product:', error);
      } finally {
        console.error('Error updating product');
      }
    };
  
    return { updateProducts };
}

export default useUpdateProduct;