import React from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeader,
  EuiText,
  EuiTextColor,
} from "@elastic/eui";
import {
  getCreateSubBreadCrumbs,
  getManangeBreadCrumbs,
  getUsersListBreadCrumbs,
} from "../utils/breadCrumbs"
import axios from "axios";

const Header = ({id}) => {
  const navigate = useNavigate();
  const location = useLocation();
  //const username = useAppSelector((zoom) => zoom.auth.userInfo?.name);
  const username = 'Admin'
  //const isDarkTheme = useAppSelector((zoom) => zoom.auth.isDarktheme);
  const [braekCrumbs, setBreadCrumbs] = React.useState([{ text: "Dashboard" }]);
  const [isResponsive, setIsResponsive] = React.useState(false);
  //const dispatch = useDispatch();
  const logout = async () => {

    const token = localStorage.getItem('token');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    try {
      await axios.post('https://sinogani-api-server.onrender.com/auth/logout');
      // Clear the token from localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('uid');
      navigate('/signin');
    } catch (error) {
      console.error('Error signing out:', error);
    }

  };

  React.useEffect(() => {
    const { pathname } = location;
    if (pathname === "/userslist")
      setBreadCrumbs(getUsersListBreadCrumbs(navigate, id));
    else if (pathname === "/createsub")
      setBreadCrumbs(getCreateSubBreadCrumbs(navigate, id));
    else if (pathname === "/manageusersub")
      setBreadCrumbs(getManangeBreadCrumbs(navigate, id));

  }, [id, location, navigate]);

  const invertTheme = () => {
    //const theme = localStorage.getItem("zoom-theme");
    //localStorage.setItem("zoom-theme", theme === "light" ? "dark" : "light");
    //dispatch(changeTheme({ isDarkTheme: !isDarkTheme }));
  };

  const section = [
    {
      items: [
        <Link to={`/admin/${id}`}>
          <EuiText>
            <h2 style={{ padding: "0 1vw", fontSize: "1.5rem", fontWeight: '700' }}>
              <EuiTextColor color="#fff">SINOGANI</EuiTextColor>
            </h2>
          </EuiText>
        </Link>,
      ],
    },
    {
      items: [
        <>
          {username ? (
            <EuiText>
              <h3 style={{ fontSize: "1.5rem", fontWeight: '500' }}>
                <EuiTextColor color="white">Hello, </EuiTextColor>
                <EuiTextColor color="#0b5cff">{username}</EuiTextColor>
              </h3>
            </EuiText>
          ) : null}
        </>,
      ],
    },
    {
      items: [
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          direction="row"
          style={{ gap: "2vw" }}
        >
          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            
              <EuiButtonIcon
                iconType="moon"
                display="fill"
                size="s"
                color="ghost"
                aria-label="invert-thme-button"
              />
            
          </EuiFlexItem>

          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            <EuiButtonIcon
              onClick={logout}
              iconType="lock"
              display="fill"
              size="s"
              aria-label="logo"
            />
          </EuiFlexItem>
        </EuiFlexGroup>,
      ],
    },
  ];
  const responsivesSection = [
    {
      items: [
        <Link to="/">
          <EuiText>
            <h2 style={{ padding: "0 1vw" }}>
              <EuiTextColor color="#0b5cff">SINOGANI</EuiTextColor>
            </h2>
          </EuiText>
        </Link>,
      ],
    },
    {
      items: [
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          direction="row"
          style={{ gap: "2vw" }}
        >
          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            
              <EuiButtonIcon
                onClick={invertTheme}
                iconType="moon"
                display="fill"
                size="s"
                color="ghost"
                aria-label="invert-thme-button"
              />
            
          </EuiFlexItem>

          <EuiFlexItem grow={false} style={{ flexBasis: "fit-content" }}>
            <EuiButtonIcon
              onClick={logout}
              iconType="lock"
              display="fill"
              size="s"
              aria-label="logo"
            />
          </EuiFlexItem>
        </EuiFlexGroup>,
      ],
    },
  ];

  React.useEffect(() => {
    if (window.innerWidth < 413) {
      setIsResponsive(true);
    }
  }, []);

  return (
    <>
      <EuiHeader
        style={{ minHeight: "8vh" }}
        theme="dark"
        sections={isResponsive ? responsivesSection : section}
      />
      <EuiHeader
        style={{ minHeight: "8vh" }}
        sections={[{ breadcrumbs: braekCrumbs }]}
      />
    </>
  );
};

export default Header;
