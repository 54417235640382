import React from 'react'
import { SizeChooseContainer, SizeSelect, CheckSizeContainer, CheckSizeSub, CheckSizeImage  } from './SizeChoose.element';

const SizeChoose = ({ size, onDelete }) => {

  const icon = require('../images/close.png');

  return (
    <SizeChooseContainer>
        <SizeSelect>{size}</SizeSelect>
        <CheckSizeContainer>
          <CheckSizeSub onClick={onDelete}>
            <CheckSizeImage src={icon} />
         </CheckSizeSub>
        </CheckSizeContainer>
    </SizeChooseContainer>
  )
}

export default SizeChoose;
