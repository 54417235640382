import axios from "axios";

const useEditUserSubType = () => {
  
    const editUserSubType = async (user, startDate, endDate, selectSubType, status, closeFlyout, createToast) => {
  
      try {

        const response = await axios.put('https://sinogani-api-server.onrender.com/api/createSubTypeOfUser', {
          email: user.email,
          startDate: startDate.format("L"),
          endDate: endDate.format("L"),
          subType: selectSubType.value,
          status: status
        });
  
        if (response.status === 200) {
          createToast({ title: "Subscription updated sucessfully.", type: "success" });
          console.log('Subscription updated successfully.');
          closeFlyout(true);
        }
      } catch (error) {
        console.error('Error updating subscription:', error);
      } 
    };
  
    return { editUserSubType };
}

export default useEditUserSubType;