import styled from "styled-components"

export const RegisterInputGroup = styled.div`
display: flex;
flex-direction: column;
width: 100%;
gap: 1rem;
`;


export const RegisterInputContainer = styled.div`
display: flex;
border: 2px solid #f0f4f7 !important;
background-color: #fafbfe;
border-radius: 6px;
width: 100%;
flex-direction: row;
align-items: center;
justify-content: center;
height: 45px;
`;

export const RegisterImage = styled.img`
width: 20px;
height: 20px;
margin-left: .9rem;
`;

export const RegisterInputs = styled.input`
width: 100%;
background: #fafbfe;
height: 100%;
padding: 0 1rem;
font-size: .9rem;
outline: none;
box-sizing: border-box;
`;

export const ErrorMessage = styled.span`
color: red;
font-size: .8rem;
`;