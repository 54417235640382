import styled from "styled-components";

export const AfricaSpaceContainer = styled.div`
width: 100%;
display: flex;
background-color: #fff;
justify-content: center;
`;

export const AfrcaSpaceCountriesContainer = styled.div`
width: 1184px;
flex-direction: column;
display: flex;
overflow: hidden;
`;

export const AfricaSpaceSearchContainer = styled.div`
box-sizing: border-box;
height: 50px;
background-color: #EFEFEF;
display: flex;
flex-direction: row;
border-radius: 15px;
width: 70%;
margin: 2.5rem auto;
`;

export const AfricaSpaceSearchIcon = styled.img`
width: 20px;
height: 20px;
margin-left: 45px;
align-self: center;
filter: invert(20%) sepia(6%) saturate(2609%) hue-rotate(181deg) brightness(99%) contrast(93%);
`;

export const AfricaSpaceSearchInputs = styled.input`
width: 100%;
height: 100%;
margin-left: 25px;
font-style: normal;
font-weight: 500;
outline: none !important;
font-size: 18px;
border-radius: 15px;
color: #000000;
background-color: #EFEFEF;
`;

export const AfricaSpaceText = styled.h5`
font-style: normal;
font-weight: 600;
font-size: 1.875rem;
line-height: 138.4%;
color: #2f3a4f;
margin: 0 auto;
`;

export const AfricaSpaceCountriesGridContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fit , minmax(330px , 394px));
grid-column-gap: 1px;
grid-row-gap: 1px;
margin-bottom: 100px;
margin-top: 2rem;
width: 100%;
background-color: #E0E0E0;
overflow: hidden;
`;



