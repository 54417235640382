import styled from "styled-components";

export const FooterContainer = styled.div`
width: 100%;
height: 130px;
background-color: #2f3a4f;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const FooterTextCopyRight = styled.h4`
padding-bottom: 8px;
text-transform: uppercase;
font-weight: 700;
color: #fff;
font-size: 12px;
text-align: center;
`;

export const FooterText = styled.h4`
color: #fff;
font-size: 12px;
text-align: center;
font-weight: 500;
`;

export const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
margin-top: 16px;
`;

export const LeftButton = styled.div`
border-style: none;
font-size: .85rem;
line-height: 1.2;
height: 32.4px;
display: flex;
align-items: center;
justify-content: center;
width: 180.36px;
background-color: #fff;
border: 1px solid #fff;
border-radius: 4px;
margin-right: 8px;
text-transform: uppercase;
cursor: pointer;
font-weight: 600;
color: #282828;

vertical-align: baseline;
`;

export const RightButton = styled.div`
border-style: none;
font-size: .85rem;
line-height: 1.2;
background-color: #fff;
border: 1px solid #fff;
display: flex;
align-items: center;
justify-content: center;
height: 32.4px;
width: 195.27px;
border-radius: 4px;
text-transform: uppercase;
cursor: pointer;
font-weight: 600;
color: #282828;
vertical-align: baseline;
`;