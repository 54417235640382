import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

const useAddProduct = () => {
    const [loading, setLoading] = useState(false);

    const addProduct = async (data, isAfrica, countrySelected, categorySelected, images, videoSelected, user, sellerUid, sizeSelect, colorSelect) => {

      if (!countrySelected) {
        toast.error('Please select country location of your product');
        return;
      }
  
      if (!categorySelected) {
        toast.error('Please select the product category type');
        return;
      }
  
      if (!images) {
        toast.error('Please select the product image picture');
        return;
      }
  
      setLoading(true);
  
      try {
        const token = localStorage.getItem('token'); 
  
        if (!token) {
          setLoading(false);
          toast.error('No token found');
          return;
        }
  
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        formData.append('isAfrica', isAfrica);
        formData.append('countrySelected', countrySelected);
        formData.append('categorySelected', categorySelected);
        formData.append('user', JSON.stringify(user));
        formData.append('sellerUid', sellerUid);
        formData.append('sizeSelect', JSON.stringify(sizeSelect));
        formData.append('colorSelect', JSON.stringify(colorSelect));
  
        images.forEach(image => formData.append('images', image));
        if (videoSelected) {
          formData.append('videoSelected', videoSelected);
        }

  
        const response = await axios.post('https://sinogani-api-server.onrender.com/add/product', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        toast.success(response.data);
        setLoading(false);

      }catch (error) {
        setLoading(false);
        toast.error('Data logging error');
        console.error('Error adding product:', error);
      }
    };
  
    return { addProduct, loading };
}

export default useAddProduct;