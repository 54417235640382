import React from 'react';
import { useSelector} from 'react-redux';
import { CartContainer, CartDivider, CartEmptyContainer, CartText  , CartTabHeadContainer, CartNameProductContainer, CartHeadText, CartPriceProductContainer, CartQuantityProductContainer, CartTotalProductContainer, CartContentContainer, TotalAmountContainer, CartTotalDivider, CartFlexTotal, FirstSection, Sections, TotalAmountGlobalContainer, CartContactWhatsapp, EnptyBtnLink, EmptyBtnContainer, CartShoppingContainer, CartGlobalContainer } from './Cart.element';
import ReactWhatsapp from 'react-whatsapp';
import ModalCartAdd from '../ModalCartAdd';
import EmptyFeed from '../EmptyFeed';
import Footer from '../Footer';

const Cart = () => {

    const params = new URLSearchParams(window.location.search);


    const devise = params.get('d');   

    const cartProducts = useSelector(state => state.cart.cartItems);

    const userLocale = navigator.language || 'en-US';
    const currencyFormatter = new Intl.NumberFormat(userLocale, { style: 'currency', currency: devise, currencyDisplay: 'symbol' });

    const totalAmount = useSelector(state => state.cart.totalAmount);

    const handleBack = () => {
        window.history.back();
    };

    const message = `Hello, I would like to place an order for the product "" Ref: "CO-" found on the website www.sinogani.com. Could you please provide me with more information regarding the price, available quantity, and delivery terms? Thank you!`

    return (
        <CartGlobalContainer>
            {
                cartProducts.length === 0 ? (
                    <CartEmptyContainer>
                        <EmptyFeed text='Your shopping cart is empty'/>
                    </CartEmptyContainer>
                ) : (
                    <CartContainer>
              <CartText>Your shopping cart</CartText>
              <CartContentContainer>
              <CartDivider/>
              <CartTabHeadContainer>
                  <CartNameProductContainer>
                      <CartHeadText>Name of products</CartHeadText>
                  </CartNameProductContainer>  
                  <CartPriceProductContainer>
                      <CartHeadText>Price</CartHeadText>
                  </CartPriceProductContainer>
                  <CartQuantityProductContainer><CartHeadText>Quantity</CartHeadText>
                  </CartQuantityProductContainer>
                  <CartTotalProductContainer>
                      <CartHeadText>Total</CartHeadText>
                  </CartTotalProductContainer>
              </CartTabHeadContainer>
              <CartDivider/>
                {
                  cartProducts.map((item, index) => (
                    <ModalCartAdd product={item} devise={devise} key={index}/>
                  ))
                }
                <CartFlexTotal>
                  <FirstSection/>
                  <Sections/>
                  <Sections/>
                  <TotalAmountGlobalContainer>
                  <TotalAmountContainer>{currencyFormatter.format(totalAmount)}</TotalAmountContainer>
                  </TotalAmountGlobalContainer>
                </CartFlexTotal>
                <CartTotalDivider/>
                <CartContactWhatsapp>
                 <ReactWhatsapp number='+86 (021) 623922757' message={message} className='react__cart__whatsapp'>CONTACT VIA WHATSAPP</ReactWhatsapp>   
                </CartContactWhatsapp>
              </CartContentContainer>
            </CartContainer>
                )
            }

            <CartShoppingContainer>
                <EnptyBtnLink onClick={handleBack}>
                    <EmptyBtnContainer>Continue shopping</EmptyBtnContainer>
                </EnptyBtnLink>
            </CartShoppingContainer>

            <Footer />

        </CartGlobalContainer>
    )
}

export default Cart;
