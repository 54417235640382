import React from 'react'
import { AddProductInputGroup, AddProductInputs, AddProductLabel, AddProductErrorMessage } from './AddProductInput.element'

const AddProductInput = ({ id, label , type , register , errorMessage }) => {

    const registerInput = (id === 'contact') ? (<AddProductInputs id={id} type={type} placeholder='Ex: +22890101010' {...register}/>) : (<AddProductInputs id={id} type={type} {...register}/>);

  return (
    <AddProductInputGroup>
        <AddProductLabel htmlFor={id}>{label}</AddProductLabel>
        {registerInput}
        <AddProductErrorMessage>{errorMessage}</AddProductErrorMessage>
    </AddProductInputGroup>
  )
}

export default AddProductInput
