import React from 'react';
import { AccordionProductHeader , AccordionProductContainers, AccordionProductContainer , AccordionProductItemName , AccordionProductHeaderIcon , AccordionProductContentText  , AccordionProductContent , AccordionProductInner } from './AccordionProductDesc.element';

const AccordionProductDesc = ({contentText}) => {

    const [active , setActive] = React.useState();

    const itemName = 'Description';

    const itemContent = contentText !== undefined ? contentText : 'Non rensigner';

    const handleClick =  (question) => {
        setActive(question === active ? null : question);
    }

    const more2 = require('../images/more2.png');

    let isActive = active === itemName;

  return (
    <AccordionProductContainer>
        <AccordionProductContainers>
            <AccordionProductHeader onClick={() => handleClick(itemName)}>
                <AccordionProductItemName>{itemName}</AccordionProductItemName>
                <AccordionProductHeaderIcon isActive={isActive} src={more2}/>
            </AccordionProductHeader>

            <AccordionProductContent itemName={itemName} isActive={isActive}>
                 <AccordionProductInner id={itemName}><AccordionProductContentText>{itemContent}</AccordionProductContentText></AccordionProductInner>
            </AccordionProductContent>
        </AccordionProductContainers>
    </AccordionProductContainer> 
  );
}

export default AccordionProductDesc;
