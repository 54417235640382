import styled from "styled-components";

export const ImageChooseContainer = styled.div`
width: 90px;
height: 100%;
display: inline-block;
position: relative;
flex-direction: column;
justify-content: center;
align-items: center;
margin-right: 1rem;
background-color: #edeff3;
border-radius: .8rem;
`;

export const ImageChoose = styled.img`
width: 100%;
height: 100%;
max-width: 100%;
border-radius: .8rem;
`;

export const CheckProductContainer = styled.div`
position: absolute;
top: -9%;
right: -5%;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
cursor: pointer;
z-index: 2;

&:hover{
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}
`;

export const CheckProductSub = styled.div`
height: 20px;
width: 20px;
background-color: #fff;
border-radius: 50%;
border: 1.5px solid #dbdbdb!important;
display: flex;
align-items: center;
justify-content: center;
`;

export const CheckProductImage = styled.img`
height: 14px;
width: 14px;
padding: 2px;
align-self: center;
`;