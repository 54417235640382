import React from 'react';
import { RegisterInputGroup , RegisterInputs, ErrorMessage, RegisterInputContainer, RegisterImage} from './RegisterInput.element';

const RegisterInput = ({id, type , register , errorMessage}) => {

  const icon = id === 'email' ? require('../images/email.png') : require('../images/password.png');

  const registerInput = (id === 'email') ? (<RegisterInputs id={id} type={type} placeholder='Email' {...register}/>) : (<RegisterInputs id={id} placeholder='Password' type={type} {...register}/>);

  return (
    <RegisterInputGroup>
        <RegisterInputContainer>
            <RegisterImage src={icon} alt='logo' />
            {registerInput}
        </RegisterInputContainer>
        <ErrorMessage>{errorMessage}</ErrorMessage>
    </RegisterInputGroup>
  );
}

export default RegisterInput;
