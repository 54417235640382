import React from "react";
import { useNavigate } from "react-router-dom";

const useAuths = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) navigate("/signin");
  }, [navigate]);
};

export default useAuths;
