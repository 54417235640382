import React from 'react'
import { CheckContainer, CheckImage, CheckSub, SelectMethodText, SubTypeContainer } from './SubTypeModel.element'

const SubTypeModel = ({ text, active, onClick }) => {
    const check = require('../images/coche.png');

    let color;

    console.log(active)

    if(text === 'STANDARD'){
        color = '#48d26b';
    }else if (text === 'VIP'){
        color = '#f55653';
    }else if (text === 'GOLD'){
        color = '#f9bd18';
    }else {
        color = '#4499ec';
    }

  return (
    <SubTypeContainer color={color} onClick={onClick}>
        <SelectMethodText>{text}</SelectMethodText>
        <CheckContainer>
        <CheckSub checks={active === text}>
            <CheckImage src={check} />
        </CheckSub>
        </CheckContainer>
    </SubTypeContainer>
  )
}

export default SubTypeModel;