import React from 'react';
import { EmptyImg, EmptyProductContainer, EmptyProductText } from './EmptyProductFeed.element';

const EmptyProductFeed = () => {

    const empty = require('../images/empty.png');

  return (
    <EmptyProductContainer>

        <EmptyImg src={empty}/>
        <EmptyProductText>No product available for the selected filter</EmptyProductText>
      
    </EmptyProductContainer>
  );
}

export default EmptyProductFeed;
