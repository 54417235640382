import styled from "styled-components";

export const AddProductDescInputGroup = styled.div`
display: flex;
flex-direction: column;
height: 100%;
gap: 1.2rem;
`;

export const AddProductDescLabel = styled.label`
font-weight: 400;
font-size: 1rem;
line-height: 20px;
color: #000000;
`;

export const AddProductDescInputs = styled.textarea`
width: 100%;
height: 100%;
background: transparent;
padding: 1rem;
font-size: .9rem;
outline: none;
border: 1.5px solid #000000 !important;border: 1.5px solid #edeff3!important;
border-radius: .8rem;
box-sizing: border-box;
`;