import styled from "styled-components";

export const ModalPRContainer = styled.div`
position: fixed;
left: 0;
top: 0;
width: 100%;
height: 100%;
display: flex;
align-items: center;
z-index: 2;
justify-content: center;
background-color: rgba(0, 0, 0, .3);
`;

export const ModalPR = styled.div`
background-color: white;

padding: 2rem;
display:flex;
align-items: center;
justify-content: center;
width: 80em;
`;

export const ModalPRContentContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
height: 500px;
`;

export const ModalPRLeftContainer = styled.div`
display: flex;
flex-direction: column;
width: 500px;
height: 100%;
margin-right: 2rem;
`;

export const ModalPRRightContainer = styled.div`
flex: 1;
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
`;

export const ProductPRImgContainer = styled.div`
width: 100%;
height: 100%;
background-color: #fff;
display: flex;
position: relative;
justify-content: center;
flex-direction: column;
overflow: hidden;
`;

export const ProductPRDetailImgSelect = styled.img`
max-width: 300px;
max-height: 300px;
align-self: center;
justify-self: center;
object-fit: cover;
z-index: .5;
margin-top: 1.5rem;
margin-bottom: 1rem;
border: none;
`;

export const ProductPRDetailImgContainer = styled.div`
width: 90px;
height: 90px;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
border: solid 1px #F6F6F6 !important;


&:hover{
    border: solid 2px #D9D9D9 !important;
}
`;

export const ProductPRDetailImg = styled.img`
width: 100%;
height: 100%;
object-fit: cover !important;
border: none !important;
`;

export const ProductNameHeadContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
margin-bottom: 1rem;
`;

export const ProductLeftPRName = styled.h5`
overflow: hidden;
width: 400px;
height: auto;
text-overflow: ellipsis;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 15px;
text-transform: capitalize;
color: #000000;
`;

export const ProductPRDetailPrice = styled.div`
font-style: normal;
font-weight: 700;
font-size: 25px;
line-height: 27px;
text-transform: uppercase;
color: #eb6740;
margin-bottom: 25px;
`;

export const ProductPRDetailQuantyText = styled.p`
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 27px;
user-select: none;
text-transform: uppercase;
color: #000000;
`;

export const ProductAddContactContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;

export const ProductCloseModal = styled.img`
width: 12px;
height: 12px;
cursor: pointer;
margin-left: auto;
`;

export const ProductPRDetailSinoText = styled.h1`
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
color: #000000;
margin-bottom: 20px;
`;

export const ProductPRSizeContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
`;

export const ProducPRtDetailText = styled.div`
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #000000;
margin-bottom: .8rem;
`;

export const ProducPRtDetailTexts = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #000000;
margin-bottom: 1rem;
`;

export const ProductPRDetailQuantityContainer = styled.div`
width: 120px;
height: 40px;
display: flex;
margin-bottom: 1.5rem;
flex-direction: row;
border: 1px solid #d8dce0 !important;
border-radius: 5px;
justify-content: center;
align-items: center;
margin-right: 20px;
`;

export const ProductPRDetailQuantityOption = styled.div`
flex: 1;
cursor: pointer;
font-style: normal;
font-weight: 400;
font-size: 22px;
height: 100%;
align-items: center;
justify-content: center;
display: flex;
user-select: none !important;
line-height: 27px;
text-transform: uppercase;
color: #000000;
`;

export const ProductPRAddContainer = styled.div`
height: 40px;
width: 100%;
cursor: pointer;
background: #f55653;
font-style: normal;
font-weight: 500;
font-size: 1rem;
line-height: 13px;
color: #fff;
border-radius: 100px;
display: flex;
align-items: center;
justify-content: center;
margin-bottom: 1rem;
`;

export const ProductPRContactWhatContainer = styled.div`
height: 40px;
width: 100%;
cursor: pointer;
background: #fff;
border: 1px solid #000 !important;
border-radius: 100px;
display: flex;
`;