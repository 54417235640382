import styled from "styled-components";
import { Link } from "react-router-dom";

export const CountryLinkWrapper = styled(Link)`
display: flex;
cursor: pointer;
text-decoration: none;
width: 100%;
display: flex;
align-items: center;
`;

/*export const CountryLinkWrapper = styled.div`
display: flex;
cursor: pointer;
text-decoration: none;
width: 100%;
display: flex;
align-items: center;
`;*/

export const CountryContainer = styled.div`
width: 100%;
height: 120px;
box-sizing: border-box;
background: #FFFFFF;
position: relative;
overflow: hidden;
display: flex;
overflow: hidden;
align-items: center;
justify-content: center;
`;

export const CountryContentContainer = styled.div`
width: 77%;
display: flex;
flex-direction: row;
align-items: center;
`;

export const CountryFlagImg = styled.img`
width: 60px;
height: 40px;
border-radius: 10px;
margin-right: auto;
`;                                                                                                                                                                                        

export const CountryFlagName = styled.span`
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
color: #000000;

`;

export const CountryIndicatorImg = styled.img`
width: 1.2rem;
height: 1.2rem;
margin-left: auto;
filter: invert(20%) sepia(6%) saturate(2609%) hue-rotate(181deg) brightness(99%) contrast(93%);
`;