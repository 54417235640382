import React from "react";
import { useAppSelector } from "../stores/hooks";
import { useDispatch } from "react-redux";
import { setToasts } from "../stores/slices/UserSubSlice";

const UseToats = () => {
    const dispatch = useDispatch();
    const toats = useAppSelector((zoom) => zoom.subscription.toasts);
    const createToast = ({ title, type }: { title: string; type: any }) => {
    dispatch(setToasts(
        toats.concat({
            id: new Date().toISOString(),
            title,
            color: type,
        })
    ))
  };
  return [createToast];
};

export default UseToats;
