import styled from "styled-components"

export const RegisterSUInputGroup = styled.div`
display: flex;
flex-direction: column;
width: 100%;
gap: 1rem;
`;

export const RegisterSUISnputGroup = styled.div`
display: flex;
flex-direction: column;
flex: 1;
margin-right: .5rem
`;


export const RegisterSUInputContainer = styled.div`
display: flex;
border: 2px solid #f0f4f7 !important;
background-color: #fafbfe;
border-radius: 6px;
width: 100%;
flex-direction: row;
align-items: center;
justify-content: center;
height: 45px;
`;

export const RegisterSUInputs = styled.input`
width: 100%;
background: #fafbfe;
height: 100%;
padding: 0 1rem;
font-size: .9rem;
outline: none;
box-sizing: border-box;
`;

export const ErrorSUMessage = styled.span`
color: red;
font-size: .8rem;
`;