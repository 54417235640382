import React from 'react';
import ForgotPasswordInput from './ForgotPasswordInput';
import {yupResolver} from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Modal, ModalContainer, ModalContentContainer, ModalIcon, ModalIconContainer, ModalForgotPasswordText, ModalForgotPasswordDesc, ForgotPasswordRegister, ForgotConfirmButton, ForgotSpinnerContainer, ForgotSpinner, ReturnLoginScreen, ReturnImage, ReturnText  } from './ModalForgotPassword.element';
import toast from 'react-hot-toast';
import axios from 'axios';

const schema = yup.object({
    email: yup.string().required('Please enter an email address!').email('Please enter a valid email address!'),
});

const ModalForgotPassword = ({ handleChangeModalState }) => {

    const formRef = React.useRef(null);
    const btnSpinner = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const icon = require('../images/locked.png');
    const back = require('../images/back.png');

    const { handleSubmit , register  , formState: {errors}} = useForm({
        resolver: yupResolver(schema),
      });

    const formSubmit = data => {

        setLoading(true);

        const params = {
           email: data.email
        }

        axios.post('https://sinogani-api-server.onrender.com/auth/reset/password', params)
            .then(() => {
                setLoading(false);
                handleChangeModalState();
                toast.success('A password reset email has been sent to your email address. Please check your inbox.');
            })
            .catch((error) => {
                setLoading(false);
                toast.error(error.message);
            })

    };

    React.useEffect(() => {

        if(loading){
            btnSpinner.current.style.display = 'flex';
        }else{
            btnSpinner.current.style.display = 'none';   
        }

      }, [loading]);

  return (
    <ModalContainer>
        <Modal>
            <ModalContentContainer>
                <ModalIconContainer>
                    <ModalIcon src={icon} />
                </ModalIconContainer>

                <ModalForgotPasswordText>Forgot your password?</ModalForgotPasswordText>
                <ModalForgotPasswordDesc>Enter your email to reset it!</ModalForgotPasswordDesc>
            
                <ForgotPasswordRegister ref={formRef} onSubmit={handleSubmit(formSubmit)}>
                    <ForgotPasswordInput id='email' label='E-mail' type='email' register={{...register('email')}} errorMessage={errors.email?.message} />

                    <ForgotConfirmButton>

                        <ForgotSpinnerContainer ref={btnSpinner}>
                            <ForgotSpinner  size={25} />
                        </ForgotSpinnerContainer>

                        {!loading && <span>Confirm</span>}

                    </ForgotConfirmButton>
                </ForgotPasswordRegister>

                <ReturnLoginScreen onClick={() => handleChangeModalState()}>
                    <ReturnImage src={back} />
                    <ReturnText>Return to the login screen</ReturnText>
                </ReturnLoginScreen>
                
            </ModalContentContainer>
        </Modal>
    </ModalContainer>
  )
}

export default ModalForgotPassword
