import styled from "styled-components";

export const DdsSizeWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
margin: 1rem 0;
`;

export const DropdownSizeLabel = styled.span`
font-weight: 400;
font-size: 1rem;
line-height: 20px;
color: #000000;
`;

export const DdsSelectSizeBtn = styled.div`
width: 100%;
height: 50px;
background: #FFFFFF;
padding: 0 1rem;
margin-top: 1rem;
display: flex;
justify-content: space-between;
font-size: .9rem;
align-items: center;
cursor: pointer;
border: 1.5px solid #edeff3!important;
border-radius: .8rem;
box-sizing: border-box;  
`;

export const DdsSizeText = styled.span`
font-weight: 400;
font-size: 1rem;
line-height: 20px;
color: #000000;
`;

export const DdsSizeContent = styled.div`
display: none;
background: #FFFFFF;
z-index: 45555555;
border: 1.5px solid #edeff3!important;
margin-top: .5rem;
padding: 1.7rem;
border-radius: .8rem;
box-sizing: border-box;
box-shadow: 0 0 1px;

${DdsSizeWrapper}.active &{
    display: block;
}
`;

export const DdsSizeSearch = styled.div`
position: relative;
display: flex;
flex-direction: row;
width: 100%;
`;

export const DdsSearchSizeInput = styled.input`
width: 100%;
height: 50px;
background: #FFFFFF;
padding: 0 3rem;
font-size: .9rem;
display: flex;
outline: none !important;
border: 1.5px solid #edeff3!important;
border-radius: .8rem;
box-sizing: border-box;
`;

export const DdsSizeOptions = styled.ul`
margin-top: 10px;
max-height: 250px;
overflow-y: auto;

&::-webkit-scrollbar{
    width: 7px;
}
&::-webkit-scrollbar-track{
   background: #f1f1f1;
   border-radius: 25px;
}
&::-webkit-scrollbar-thumb{
    background: #ccc;
    border-radius: 25px;
}
`;

export const DdsSizeList = styled.li`
height: 50px;
border-radius: 5px;
display: flex;
cursor: pointer;
font-weight: 400;
font-size: 1rem;
line-height: 20px;
color: #000000;
align-items: center;
padding: 0 13px;

&:hover{
    background: #f2f2f2;
}
`;

export const DdsSizeIcon = styled.i`
font-size: 1.9rem;
transition: transform .3s linear;

${DdsSizeWrapper}.active & {
    transform: rotate(-180deg);
}
`;

export const DdsSizeIcons = styled.i`
left: 15px;
line-height: 50px;
color: #999;
font-size: 20px;
position: absolute;
`;