import styled from "styled-components";

export const EmptyContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top:100px;
`;

export const EmptyHeadText = styled.h4`
width: 440px;
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 37px;
text-align: center;
color: #000000;
margin-bottom: 60px;
`;

export const EnptyBtnLink = styled.div`
width: 250px;
height: 50px;
text-decoration: none;
cursor: pointer;
`;

export const EmptyBtnContainer = styled.div`
width: 100%;
height: 100%;
border: 1px solid #000000 !important;
display: flex;
align-items: center;
justify-content: center;
border-radius: 30px;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
text-align: center;
color: #000000;
`

