import styled from "styled-components";

export const SubTextContainer = styled.div`
display: flex;
width: 100%;
flex-direction: row;
align-items: center;
margin: 1rem 0;
`;

export const SubTextImg = styled.img`
width: 15px;
height: 15px;
margin-right: 1rem;
display: flex;
`; 

export const SubText = styled.h4`
font-size: 1.2rem;
font-style: normal;
color: #676D7C;
font-weight: 500;
`;