import React from 'react'
import { CheckProductContainer, CheckProductImage, CheckProductSub, ImageChoose, ImageChooseContainer } from './ImageSelected.element'

const ImageSelected = ({ url, onDelete }) => {

  const icon = require('../images/close.png');

  return (
    <ImageChooseContainer>
        <ImageChoose src={url} alt='Image Selected'/>
        <CheckProductContainer>
          <CheckProductSub onClick={onDelete}>
            <CheckProductImage src={icon} />
         </CheckProductSub>
        </CheckProductContainer>
    </ImageChooseContainer>
  )
}

export default ImageSelected
