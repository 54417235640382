import { createSlice } from "@reduxjs/toolkit";


const items = localStorage.getItem('cartItems') !== null ? JSON.parse(localStorage.getItem('cartItems')) : [];

const totalAmount = localStorage.getItem('totalAmount') !== null ? JSON.parse(localStorage.getItem('totalAmount')) : 0;

const totalQuantity = localStorage.getItem('totalQuantity') !== null ? JSON.parse(localStorage.getItem('totalQuantity')) : 0;


const setItemFunc = (item , totalAmount , totalQuantity) => {

    localStorage.setItem('cartItems' , JSON.stringify(item));

    localStorage.setItem('totalAmount' , JSON.stringify(totalAmount));

    localStorage.setItem('totalQuantity' , JSON.stringify(totalQuantity));

}

const initialState = {
    cartItems: items,
    totalQuantity: totalQuantity,
    totalAmount: totalAmount,
}

const cartSlice = createSlice({
    name: 'cart',
    initialState: initialState,

    reducers: {
        addToCart(state , action){
            const newItem = action.payload
            const existingItem = state.cartItems.find(item => item.uid === newItem.uid)

            state.totalQuantity++

            if(!existingItem){
                state.cartItems.push({
                    uid: newItem.uid,
                    name: newItem.productName,
                    productDesc: newItem.description,
                    price: newItem.price,
                    image: newItem.image,
                    refNum: newItem.contact,
                    quantity: 1,
                    totalPrice: newItem.price
                })
            }else{
                existingItem.quantity++
                existingItem.totalPrice = Number(existingItem.totalPrice) + Number(newItem.price)
            }

            state.totalAmount = state.cartItems.reduce((total , item) => total + Number(item.price) * Number(item.quantity), 0);

            setItemFunc(state.cartItems.map(item => item) , state.totalAmount , state.totalQuantity);

        },
         
        decrementQuantity(state , action){
            const uid  = action.payload;
            const existingItem = state.cartItems.find(item => item.uid === uid);
            state.totalQuantity--;

            if(existingItem.quantity === 1){
                state.cartItems = state.cartItems.filter(item => item.uid !== uid)
            }else {
                existingItem.quantity--;
                existingItem.totalPrice = Number(existingItem.totalPrice) - Number(existingItem.price)
            }

            state.totalAmount = state.cartItems.reduce((total , item) => total + Number(item.price) * Number(item.quantity), 0);
            
            setItemFunc(state.cartItems.map(item => item) , state.totalAmount , state.totalQuantity);
                
        },


        removeFromCart(state , action){
            const uid = action.payload;
            const existingItem = state.cartItems.find(item => item.uid === uid);

            if(existingItem){
                state.cartItems = state.cartItems.filter(item => item.uid !== uid);
                state.totalQuantity = state.totalQuantity - existingItem.quantity;
            }

            state.totalAmount = state.cartItems.reduce((total , item) => total + Number(item.price) * Number(item.quantity), 0);
            
            setItemFunc(state.cartItems.map(item => item) , state.totalAmount , state.totalQuantity);
                
        },

    },
});

export const { addToCart  , removeFromCart , decrementQuantity} = cartSlice.actions;
export default cartSlice;