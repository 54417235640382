import styled from "styled-components";

export const ProductListGlobalContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
background-color: #fff;
align-items: center;
justify-content: center;
`;

export const ProductListContentContainer = styled.div`
display: flex;
flex-direction: column;
background-color: #fff;
width: 1184px;
`;

export const ProductListDatacrumbsContainer = styled.div`
width: 100%;
height: 3.5rem;
`;

export const ProductListTitle = styled.h1`
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 34px;
margin-bottom: 8px;
color: #000000;
`;

export const ProductListUnder = styled.p`
font-weight: 400;
font-size: .9rem;
line-height: 20px;
color: #000000;
`;

export const SearchFilterContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
margin-top: 2rem;
`;

export const AllProductNumberTitle = styled.h1`
font-style: normal;
font-weight: 600;
width: 50%;
font-size: 24px;
line-height: 34px;
margin: auto 0;
margin-bottom: 8px;
color: #000000;
`;

export const AllSearchFilterRightContainer = styled.div`
width: 50%;
margin-left: auto;
display: flex;
flex-direction: row;
`;

export const TabsSearchBar = styled.div`
box-sizing: border-box;
height: 40px;
border: 2px solid #DBDBDB !important;
display: flex;
border-radius: .7rem;
margin-right: 1rem;
flex-direction: row;
width: 55%;
`;

export const TabsFilter = styled.div`
box-sizing: border-box;
height: 40px;
border: 2px solid #DBDBDB !important;
display: flex;
border-radius: .7rem;
margin-right: 1rem;
align-items: center;
justify-content: center;
cursor: pointer;
width: 18%;
flex-direction: row;
`;

export const TabsFilterIcon = styled.img`
width: 20px;
height: 20px;
margin-right: 10px;
`;

export const TabsAddProduct = styled.div`
box-sizing: border-box;
height: 40px;
display: flex;
background-color: #000;
border-radius: .7rem;
align-items: center;
justify-content: center;
cursor: pointer;
width: 22%;
flex-direction: row;
`;

export const TabsSearchIcon = styled.img`
width: 20px;
height: 20px;
margin-left: 15px;
align-self: center;
`;

export const TabsSearchInput = styled.input`
width: 100%;
height: 100%;
margin-left: 10px;
margin-right: 10px;
font-style: normal;
font-weight: 400;
outline: none !important;
background-color: #fff;
font-size: 14px;
line-height: 17px;
color: #000000;
`;

export const TabsSearchText = styled.h1`
font-style: normal;
font-weight: 500;
outline: none !important;
color: #000;
font-size: 14px;
line-height: 17px;
color: #000000;
`;

export const TabsAddText = styled.h1`
font-style: normal;
font-weight: 500;
outline: none !important;
color: #000;
font-size: 14px;
line-height: 17px;
color: #fff;
`;

export const TabsProductHeadTitleContainer = styled.div`
width: 100%;
background-color: #f1f1f1;
height: 32.4px;
display: flex;
flex-direction: row;
border-radius: 8px;
margin-top: 1.5rem;
`;

export const TabsProductHeadPNContainer = styled.div`
height: 100%;
flex: 2.5;
display: flex;
align-items: center;
`;

export const TabsProductHeadTitle = styled.div`
height: 100%;
flex: 1;
display: flex;
align-items: center;
`;

export const TabsProductTitle = styled.p`
font-weight: 400;
font-size: .9rem;
margin-left: 2rem;
line-height: 20px;
color: #000000;
`;