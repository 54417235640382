import styled from "styled-components"

export const AddProductInputGroup = styled.div`
display: flex;
flex-direction: column;
gap: 1.2rem;
margin-bottom: 30px;
`

export const AddProductLabel = styled.label`
font-weight: 400;
font-size: 1rem;
line-height: 20px;
color: #000000;
`;

export const AddProductInputs = styled.input`
width: 100%;
height: 50px;
background: #FFFFFF;
padding: 0 1rem;
font-size: .9rem;
outline: none;
border: 1.5px solid #edeff3!important;
border-radius: .8rem;
box-sizing: border-box;
`;

export const AddProductErrorMessage = styled.span`
color: red;
font-size: .8rem;
`