import React from 'react';
import { EmptyContainer, EmptyHeadText} from './EmptyFeed.element';

const EmptyFeed = ({text}) => {

  return (
    <EmptyContainer>
        <EmptyHeadText>{text}</EmptyHeadText>
        {/*<EnptyBtnLink onClick={handleBack}>
            <EmptyBtnContainer>Continuer vos achats</EmptyBtnContainer>
        </EnptyBtnLink>*/}
    </EmptyContainer>
  );
}

export default EmptyFeed;
