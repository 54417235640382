import styled from "styled-components";

export const SizeChooseContainer = styled.div`
width: auto;
height: auto;
display: flex;
position: relative;
flex-direction: column;
justify-content: center;
align-items: center;
margin-right: 10px;
`;

export const SizeChooseContainers = styled.div`
width: auto;
height: auto;
display: flex;
position: relative;
cursor: pointer;
flex-direction: column;
justify-content: center;
align-items: center;
margin-right: 10px;
`;

export const SizeSelects = styled.span`
padding: 1rem;
border: 1.5px solid #dbdbdb!important;
font-style: normal;
font-weight: 500;
outline: none !important;
color: #000;
text-transform: uppercase;
font-size: 14px;
line-height: 17px;
color: #000000;
`;

export const SizeSelect = styled.span`
padding: 1rem;
border: 1.5px solid #dbdbdb!important;
font-style: normal;
margin-right: 10px;
font-weight: 500;
outline: none !important;
color: #000;
text-transform: uppercase;
font-size: 14px;
line-height: 17px;
color: #000000;
`;

export const CheckSizeContainer = styled.div`
position: absolute;
top: -10%;
right: 1%;
cursor: pointer;
z-index: 2;
`;

export const CheckSizeContainers = styled.div`
position: absolute;
top: -15%;
right: -7%;
cursor: pointer;
display: ${({select}) => (select ? 'flex' : 'none')};
z-index: 2;
`;

export const CheckSizeSub = styled.div`
height: 20px;
width: 20px;
background-color: #fff;
border-radius: 50%;
border: 1.5px solid #dbdbdb!important;
display: flex;
align-items: center;
justify-content: center;
`;

export const CheckSizeImage = styled.img`
height: 14px;
width: 14px;
padding: 2px;
align-self: center;
`;

export const CheckSizeImages = styled.img`
height: 18px;
width: 18px;
padding: 2px;
align-self: center;
`;
