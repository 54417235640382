import React from 'react'
import { ColorsChoosingContainers, ColorsChoosing, CheckColorContainers, CheckColorSub, CheckColorImages } from './ColorChoosing.element'

const ProductColor = ({ bg, active, onClick }) => {

const icon = require('../images/coche.png');

  return (
    <ColorsChoosingContainers onClick={onClick}>
        <ColorsChoosing style={{ backgroundColor: bg }}/>
        <CheckColorContainers select={active === bg} >
          <CheckColorSub>
            <CheckColorImages src={icon} />
         </CheckColorSub>
        </CheckColorContainers>
    </ColorsChoosingContainers>
  )
}

export default ProductColor
