import React from 'react'
import { CheckSizeContainers, CheckSizeImages, CheckSizeSub, SizeChooseContainers, SizeSelects } from './SizeChoose.element';

const ProductSize = ({ size, active, onClick }) => {
    const icon = require('../images/coche.png');

  return (
    <SizeChooseContainers onClick={onClick}>
        <SizeSelects>{size}</SizeSelects>
        <CheckSizeContainers select={active === size}>
          <CheckSizeSub>
            <CheckSizeImages src={icon} />
         </CheckSizeSub>
        </CheckSizeContainers>
    </SizeChooseContainers>
  )
}

export default ProductSize;
