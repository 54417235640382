import React from 'react';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from "react-router-dom";
import Header from '../Header';
import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
} from "@elastic/eui";
import dashboard1 from '../../assets/dashboard1.png';
import dashboard2 from '../../assets/dashboard2.png';
import dashboard3 from '../../assets/dashboard3.png';
export let id;


const AdminPage = () => {
  useAuth();

  const navigate = useNavigate();

  const url = window.location.href;

  var partiesURL = url.split('/');

  var indexAdmin = partiesURL.indexOf('admin');

  if (indexAdmin !== -1 && indexAdmin < partiesURL.length - 1) {
    id = partiesURL[indexAdmin + 1];
  }

  
  return (
      <>
        <div 
        style={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          backgroundColor: "#F7F8FC",
        }}>

        <Header id={id} />
        <EuiFlexGroup justifyContent="center" alignItems="center" style={{margin: "5vh 10vw"}}>
        <EuiFlexItem>
          <EuiCard style={{ color: "black"}} fontSize="1rem" background="white" fontWeight='500' icon={<EuiImage alt="icon" size="5rem" src={dashboard1} />} 
          title={`User list`}
          description="View the user list"
          onClick={() => {
            console.log("clicked");
            navigate('/userslist')
          }}
          paddingSize="xl" />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiCard icon={<EuiImage alt="icon" size="100%" src={dashboard2} />} 
          title={`Create a subscription`}
          description="Create user subscription"
          onClick={() => navigate('/createsub')}
          paddingSize="xl" />
        </EuiFlexItem>

      <EuiFlexItem>
          <EuiCard style={{ color: "black", fontSize: '1rem', fontWeight: '500', background: "#fff"}} icon={<EuiImage alt="icon" size="5rem" src={dashboard3} />} 
          title={`Subscription management`}
          description="View the user subscription list and management user subscription."
          onClick={() => navigate('/manageusersub')}
          paddingSize="xl" />
        </EuiFlexItem>
      </EuiFlexGroup>
         
        </div>
      </>
    )

}

export default AdminPage;