import styled , { createGlobalStyle } from 'styled-components';
<style>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,500&family=Inter:wght@400;500;600;700&display=swap');
</style>

const GlobalStyle = createGlobalStyle`

:root{
  --primaryColor: #f1f1f1;
  --black: #222;
  --black2: #555;
  --black3: #252525;
  --black4: #000;
  --black5: #212529;
  --orange: #eb0028;
  --white: #fff;
  --grey: #959595;
  --grey2: #666;
  --grey3: #ccc;
  --secondaryColor: #2b1f4d;
  --yellow: #B7A857;
  --green: #59b210;
  --blue: rgb(56, 10, 223);
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: none !important;
    
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react__product__whatsapp{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.938rem;
    background-color: transparent !important;
    line-height: 1.125rem;
    text-transform: uppercase;
    color: #FFFFFF;
}

.react__product__pr__whatsapp{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.938rem;
    background-color: transparent !important;
    line-height: 1.125rem;
    color: #000;
}

.react__cart__whatsapp{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

body{
    width: 100%;
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Montserrat',sans-serif,
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.active__pagination{
    background-color: #e0e0e0;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    color: #000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    line-height: 17px;
}

.active__tabs{
    border-bottom: 0.125rem solid #000 !important;
    padding: 0.813rem 0.1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 0.938rem;
    cursor: pointer;
    margin-right: 5rem;
    background-color: #f1f1f1;
    color: #000000;
}

.tab{
    padding: 0.813rem 0.1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 0.938rem;
    cursor: pointer;
    margin-right: 5rem;
    background-color: #f1f1f1;
    color: #000000;
}

@media only screen and (max-width: 960px){
    .react__wathsapp{
        font-size: .5rem;
        font-weight: 700;
        line-height:1.25rem;
        margin-bottom: 1.188rem;
    }

    .react__wathsapps{
        font-size: .5rem;
        font-weight: 700;
        line-height: 1.25rem;
    }
}
`;

export const breakpoints = {
    largeDesktop: '1200px',
    desktop: '992px',
    tablet: '768px',
    phone: '576px',
    smallPhone: '425px'
};

export const Container = styled.div`
width: 100%;
max-width: 1280px;
margin-right: auto;
margin-left:auto;
`;

export default GlobalStyle;