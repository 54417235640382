import React from 'react'
import RegisterInput from '../RegisterInput';
import { useState } from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useNavigate } from "react-router-dom";
import { AndText, BecomeMenberText, ConnexionButton, FirstNameAndLastNamerCOntainer, ForgotText, FormRegisterContainer, IAgreeContainer, LoginBar, LoginBarContainer, LoginBarSUContainer, LoginContainer, LoginDescText, LoginLContainer, LoginLeftContainer, LoginMethod, LoginMethodContainer, LoginMethodImg, LoginMethodSecond, LoginMethodText, LoginOrText, LoginRightContainer, LoginRightImg, LoginSUOrText, LoginText, LogoContainer, LogoImg, LogoName, PrivacyText, RegisterSpinner, RegisterSpinnerContainer, ReminberMeContainer,  SelectSubTypeContainer, SignInContainer, SignUpButton, SignUpContainer, SignUpDesc, SignUpDescs, SignUpDescText, SignUpLogoContainer, SignUpLogoImg, SignUpMenberText, SignUpSIContainer, SignUpText, SignUpTexts, SignuTextConatiner } from './SignInPage.element';
import RegisterSUImput from '../RegisterSUImput';
import { Toaster, toast } from 'react-hot-toast';
import useAuth from '../../hooks/useAuth';
import ModalForgotPassword from '../ModalForgotPassword';
import { convertDate } from '../../utils/util';
import { SubTypeData } from '../../utils/util';
import SubTypeModel from '../SubTypeModel';
import axios from 'axios';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const schema = yup.object({
    email: yup.string().required('Veuillez saisir un addresse email!').email('Veuillez saisir un email valide!'),
    password: yup.string().required('Please enter your password').min(8, 'Password must contain at least 8 characters'),

  })

  const schemas = yup.object({
    name: yup.string().required('Please enter your name'),
    surname: yup.string().required('Please enter your surname'),
    email: yup.string().required('Please enter an email address!').email('Please enter a valid email address!'),
    password: yup.string().required('Please enter your password').min(8, 'Password must contain at least 8 characters'),
  })

const SignInPage = ({db, firebase}) => {
    useAuth();


    const logo = require('../../images/logo.png');
    const fb = require('../../images/fb.png');
    const google = require('../../images/google.png');
    const signin = require('../../images/signin.png');
    
    const formRef = React.useRef(null);
    const btnSpinner = React.useRef(null);
    const btnText = React.useRef(null);
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(false);
    const [agree, setAgree] = useState(false);
    const [activeSub, setActiveSub] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();

    const { handleSubmit , register  , formState: {errors}} = useForm({
        resolver: active ? yupResolver(schema) : yupResolver(schemas),
      });

      const handleSignUpClick = (state) => {
        setActive(state);
    }

    const handleClick = () => {setAgree(!agree)}

    const updateModalOpen = () => {

        setModalOpen(!modalOpen);

    }

    function sendEmail(to, subject, body) {
        const emailData = {
          to: to,
          subject: subject,
          body: body,
        };
      
        axios.post('https://send-mail-manager-1.onrender.com/api/send-email', emailData)
        .then(response => {
            console.log(response.data); 
          })
          .catch(error => {
            console.error('Erreur lors de la requête:', error);
          });

      }

      function serviceMail(to, subject, body){
        const emailData = {
            to: to,
            subject: subject,
            body: body,
          };

          axios.post('https://send-mail-manager-1.onrender.com/api/service-email', emailData).then(response => {
            console.log(response.data); 
          })
          .catch(error => {
            console.error('Erreur lors de la requête:', error);
          });

      }

    
    const formSubmit = data => {

    
        setLoading(true);
    
           if(!active){
             if(agree){
                if(activeSub !== null){
        
                    const users = {
                        email: data.email,
                        name: data.name,
                        subStatus: false,
                        subBeginDate: "--",
                        subEndStatusDate: "--",
                        surname: data.surname,
                        createDate: convertDate(),
                        userName: `${data.name} ${data.surname}`,
                        sub: false,
                        cancel: false,
                        subType: activeSub,
                    };
    
                        const u = {
                            email: users.email,
                            password: data.password,
                            ...users
                        }

                        axios.post('https://sinogani-api-server.onrender.com/auth/register', u)
                            .then(response => {
                                
                                setLoading(false);
                                setActive(true);
                                setEmail(data.email);
                                setPassword(data.password);
                                toast.success('Registration successfully completed!');

                                const serviceEmailAddress = 'contact@sinogani.com';
                                const serviceSubject = 'User Subscription';
                                const serviceBody = ``;

                                const emailAddress = response.data.email;
                                console.log(response);
                                console.log(emailAddress);
                                const subject = 'Payments';
                                const body = `Subject: Subscription Confirmation and Payment Instructions

                                Dear ${data.name} ${data.surname},
                                
                                We warmly welcome you to Sinogani! You are now about to become a part of a platform that helps builds an internationalnetwork to promote business and cultural exchanges opportunities. Thank you for choosing to subscribe to our ${activeSub} subscription service.
                                
                                Your selection of the ${activeSub} subscription has been successfully recorded. To fully enjoy the benefits and to get your ID confirmation code of ${activeSub} package, kindly confirm your subscription by making down a bank transfer depends on your country of residency, to the following account:
                                
                                1. Bank: BANK OF CHINA If you are living in China

                                Beneficiary Name: Shanghai GANI Consulting Co., Ltd
                                Bank Address: Bank of China Shanghai Branch, Xian Xia West Road, Shanghai, China
                                Postal Code: 200051
                                Domicile Address: No.3 Lane 299 Xian Xian West Road, Shanghai, China
                                SWIFT/BIC: BKCHCNBJ300
                                Account Number: 6232630800102581216
                                Telephone: +86 (021) 623922757
                                
                                银行账户和详细信息
                                
                                Company Name: 上海甘尼企业管理咨询有限公司
                                Bank Name: 中国银行
                                Address: 中国银行上海市仙霞西路支行
                                Postal Code: 200051
                                Address: 中国上海市长宁区仙霞西路299弄3号
                                SWIFT Code: BKCHCNBJ300
                                Telephone: +86（021）623922757
                                Account: 6232630800102581216
                                
                                2. ECOBANK if you are living in African countries
                                
                                Bank: Ecobank Togo
                                Beneficiary Name: Shanghai GANI Co., Ltd
                                Name: Amehon Essivi
                                Account Number: 140207738001
                                Bank Address: Lomé, Togo
                                SWIFT/BIC: ECOCTGTG
                                
                                
                                Please send the receipt of your bank transfer to the email address contact@sinogani.com as soon as the payment is completed.
                                
                                Within 8 working days, we will contact you back through your valid contract number for further instructions and guidance.

                                We are delighted to have you as a member and remain at your disposal for any further questions.
                                
                                Best regards,
                                The Sinogani Team
                                
                                
                                
                                NB: What's customer ID Code ? The ID code is serial number that helps Sino GANI team to identify each ones of its subscribers.`;

                                sendEmail(emailAddress, subject, body);

                                serviceMail(serviceEmailAddress, serviceSubject, serviceBody);
                                   
                            })
                            .catch((error) => {
                                setLoading(false);
                                console.log(error);
                                toast.error(error.message);
                            })
     
                    
                 }else {
                    setLoading(false);
                    toast.error('Please select your subscription type');
                     
                 } 
             }else {
                setLoading(false);
                toast.error('Please read and accept our terms and conditions and privacy policy.');
             }
           }else{

            const u = {
                email: data.email,
                password: data.password,
            }

            axios.post('https://sinogani-api-server.onrender.com/api/login', u)
              .then(response => {
                setLoading(false)
                if(response.data.email === 'admin@sinogani.com') {
                    navigate(`/admin/${response.data.uid}`);
                }else navigate(`/user/${response.data.uid}`);

                const token = response.data.token;

                console.log(token);
                console.log(response.data.uid)
                console.log(response.data.email)

                localStorage.setItem('token', token);
                localStorage.setItem('uid', response.data.uid);
                localStorage.setItem('email', response.data.email);

              })
              .catch((error) => {
                setLoading(false);
                toast.error(error.message);
            });

           }
              
    
      }

    const handleItemClick = (itemValue) => {
        setActiveSub(itemValue === activeSub ? null : itemValue);
      };


      React.useEffect(() => {

        if(!active){

            if(loading){
                btnSpinner.current.style.display = 'flex';
                btnText.current.textContent = 'Account creation...'
            }else{
                btnSpinner.current.style.display = 'none';
                btnText.current.textContent = 'Create Account';    
            }

        }else{

            if(loading){
                btnSpinner.current.style.display = 'flex';
                btnText.current.textContent = 'Loading...'
            }else{
                btnSpinner.current.style.display = 'none';
                btnText.current.textContent = 'Log in';    
            }

        }

      }, [loading, active]);

    

  return (
    <SignInContainer>

        <Toaster toastOptions={{duration: 4000}} />

        {modalOpen && <ModalForgotPassword  handleChangeModalState={updateModalOpen}/>}

        <LoginContainer>

            <LoginLeftContainer>

                {
                    active === true ? (
                        <LoginLContainer>

                    <LogoContainer>

                        <LogoImg src={logo} alt="logo" />
                        <LogoName>SINOGANI</LogoName>

                    </LogoContainer>

                    <LoginText>Log in to your Account</LoginText>
                    <LoginDescText>Welcome back! Select method to log in:</LoginDescText>

                    <LoginMethodContainer>

                        <LoginMethod>

                            <LoginMethodImg src={google} alt='login with facebook' />
                            <LoginMethodText>Google</LoginMethodText>

                        </LoginMethod>


                        <LoginMethodSecond>

                            <LoginMethodImg src={fb} alt='login with facebook' />
                            <LoginMethodText>Facebook</LoginMethodText>

                        </LoginMethodSecond>

                    </LoginMethodContainer>


                    <LoginBarContainer>

                        <LoginBar />
                        <LoginOrText>or continue with email </LoginOrText>
                        <LoginBar />

                    </LoginBarContainer>

                    <FormRegisterContainer ref={formRef} onSubmit={handleSubmit(formSubmit)}>

                        <RegisterInput value={email !== '' ? email: null} id='email' type='email' register={{...register('email')}} errorMessage={errors.email?.message}/>
                        <RegisterInput value={password !== '' ? password: null} id='password' type='password' register={{...register('password')}} errorMessage={errors.password?.message}/>

                        <ReminberMeContainer>

                        <FormGroup>
                          <FormControlLabel control={<Checkbox style ={{
                            color: "#2f3a4f",
                    }} defaultChecked />} label={<span style={{ color: '#8d9aae', fontSize: '.8rem' }}>Remenber me</span>} />
                        </FormGroup>

                        <ForgotText onClick={updateModalOpen}>Forgot Password?</ForgotText>

                    </ReminberMeContainer>

                    <ConnexionButton>
                        <RegisterSpinnerContainer ref={btnSpinner}>
                            <RegisterSpinner size={25}/>
                        </RegisterSpinnerContainer>

                        <span ref={btnText}>Log in</span>
                    </ConnexionButton>

                    </FormRegisterContainer>


                    <SignuTextConatiner>

                        <SignUpDesc>Don't have an account?</SignUpDesc>
                        <SignUpText onClick={() => handleSignUpClick(false)}>Create account</SignUpText>

                    </SignuTextConatiner>

                </LoginLContainer>
                    )
                    
                    : 
                    
                    (
                        <SignUpContainer>

                            <SignUpLogoContainer>
                                <SignUpLogoImg src={logo} alt="logo" />

                                <SignUpSIContainer>
                                    <SignUpDescs>Already a member?</SignUpDescs>
                                    <SignUpTexts onClick={() =>  handleSignUpClick(true)}>Sign in</SignUpTexts>
                                </SignUpSIContainer>
                            </SignUpLogoContainer>

                            <SignUpMenberText>Become a Member</SignUpMenberText>
                            <SignUpDescText>Smart Investing, Wholesale Buying</SignUpDescText>

                            <SelectSubTypeContainer>

                                {
                                    SubTypeData.map(item => <SubTypeModel active={activeSub} text={item.value} key={item.id} onClick={() => handleItemClick(item.value)} />)
                                }
                                
                            </SelectSubTypeContainer>

                            <LoginBarSUContainer>

                                <LoginBar />
                                <LoginSUOrText>continue with create account</LoginSUOrText>
                                <LoginBar />

                            </LoginBarSUContainer>

                            <FormRegisterContainer ref={formRef} onSubmit={handleSubmit(formSubmit)}>

                                <FirstNameAndLastNamerCOntainer>

                                <RegisterSUImput id='name' group={true} placeholder='First Name' type='text' register={{...register('name')}} errorMessage={errors.name?.message}/>
                                        <RegisterSUImput id='surname' group={true} placeholder='Last Name' type='text' register={{...register('surname')}} errorMessage={errors.surname?.message}/>

                                </FirstNameAndLastNamerCOntainer>

                                <RegisterSUImput id='email' group={false}  type='email' register={{...register('email')}} errorMessage={errors.email?.message}/>
                                <RegisterSUImput id='password' group={false}  type='password' register={{...register('password')}} errorMessage={errors.password?.message}/>

                                <IAgreeContainer>

                                    <FormGroup onClick={handleClick}>
                                        <FormControlLabel style={{
                                            margin: 0,
                                        }} control={<Checkbox style ={{
                                            color: "#2f3a4f",
                                        }} />} label={<span style={{ color: '#8d9aae', fontSize: '.8rem' }}>I agree to your</span>} />
                                    </FormGroup>

                                    <PrivacyText>Privacy Policy</PrivacyText>
                                    <AndText>and</AndText>
                                    <PrivacyText>Terme of use</PrivacyText>
                        

                                </IAgreeContainer>

                                <SignUpButton>

                                    <RegisterSpinnerContainer ref={btnSpinner}>
                                        <RegisterSpinner size={25}/>
                                    </RegisterSpinnerContainer>

                                    <span ref={btnText}>Create Account</span>

                                </SignUpButton>

                            </FormRegisterContainer>

                        </SignUpContainer>
                    )
                }

            </LoginLeftContainer>

            <LoginRightContainer>
                <LoginRightImg src={signin} />

                <BecomeMenberText>Explore an array of exclusive features by logging into your account, providing a personalized and secure experience.</BecomeMenberText>
            </LoginRightContainer>

        </LoginContainer>

    </SignInContainer>
  )
}

export default SignInPage;