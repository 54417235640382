import React from 'react'
import { ModalPR, ModalPRContainer, ModalPRContentContainer, ModalPRLeftContainer, ModalPRRightContainer, ProducPRtDetailText, ProducPRtDetailTexts, ProductAddContactContainer, ProductCloseModal, ProductLeftPRName, ProductNameHeadContainer, ProductPRAddContainer, ProductPRContactWhatContainer, ProductPRDetailImg, ProductPRDetailImgContainer, ProductPRDetailImgSelect, ProductPRDetailPrice, ProductPRDetailQuantityContainer, ProductPRDetailQuantityOption, ProductPRDetailQuantyText, ProductPRDetailSinoText, ProductPRImgContainer, ProductPRSizeContainer } from './ModalRecommandedProduct.element';
import { useDispatch , useSelector } from 'react-redux';
import { addToCart } from '../stores/shopping-cart/cartSlice';
import { toast } from 'react-hot-toast';
import Slider from "react-slick";
import { ProductImgsContainer, ProductSizesContentContainer } from './pages/ProductDetail.element';
import ProductSize from './ProductSize';
import axios from 'axios';
import { generateMeetingId } from '../utils/generatemeetingId';
import ReactWhatsapp from 'react-whatsapp';

const ModalRecommandedProduct = ({ product , devise, changeState}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        padding: '20px',
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

      const dispatch = useDispatch();
      const cartItems = useSelector(state => state.cart.cartItems);

    const userLocale = navigator.language || 'en-US';
    const currencyFormatter = new Intl.NumberFormat(userLocale, { style: 'currency', currency: devise, currencyDisplay: 'symbol' });  

    const close = require('../images/close.png');

    const [imgUrl , setImgUrl] = React.useState(product.images[0]);
    const [quantity , setQuantity] = React.useState(1);
    const [activeSize, setActiveSize] = React.useState(null);
    const [oderId, setOrderId] = React.useState(generateMeetingId());

    const handleSizeClick = (itemValue) => {
        setActiveSize(itemValue === activeSize ? null : itemValue);
    };

    const insideCart = cartItems.some(p => p.uid === product.uid);

    const handleAddToCart = () => {
        
    if(insideCart){
      toast.success('Product already added to cart')
    }else{
      toast.success('Product add to cart!')
    }


    const { uid , productName , description, price, images, contact } = product;

    const image = images[0];

    const totalPrice = price;

    dispatch(addToCart({ uid , productName, description , price , image, contact , totalPrice }))

    changeState();

    }

    const message = `Hello, I would like to place an order for the product "${product.productName}" Ref: "PO-${oderId}" found on the website www.sinogani.com. Could you please provide me with more information regarding the price, available quantity, and delivery terms? Thank you!`;

    function serviceMail(to, subject, body){
        const emailData = {
            to: to,
            subject: subject,
            body: body,
          };
    
          axios.post('https://send-mail-manager-1.onrender.com/api/service-email', emailData)
    
          .then(response => {
            console.log(response.data); 
          })
          .catch(error => {
            console.error('Erreur lors de la requête:', error);
          });
    
      }

    const handleProductClickContact = p => {

        setOrderId(generateMeetingId());
    
        const emailAddress = 'contact@sinogani.com';
        const subject = `PO-${oderId}`;
    
        const body = `Subject: Product Order Placed
    
            Product Image Url: ${product.images[0]},
            
            Product Name: ${product.productName}
    
            Product Price: ${currencyFormatter.format(quantity*product.price)}
    
            Product Quantity: ${quantity}
    
            Product Size Select: ${activeSize}
    
            Product Color Select: ${null}
    
            Product Seller Email: ${product.sellerMail}
    
            Product Seller Contact: ${product.contact}
        `;
    
        serviceMail(emailAddress, subject, body);

        changeState();
    
      }

    const handleIncrementQuantity = () => {
        setQuantity(quantity + 1);
      }
    
      const handleDecrementQuantity = () => {
        if(quantity > 1) setQuantity(quantity - 1);
      }

  return (
    <ModalPRContainer>
        <ModalPR>
            <ModalPRContentContainer>
                <ModalPRLeftContainer>
                    <ProductPRImgContainer>
                        <ProductPRDetailImgSelect src={imgUrl} loading='lazy'/> 

                        {
                            product.images.length > 1 ? (<div className='slider-container'>
                            <Slider {...settings}>
                            
                            {
                                 product.images.map((url , index) => <ProductPRDetailImgContainer key={index} onClick={() => {
                                  setImgUrl(url);
                                 }}>
                                    <ProductPRDetailImg src={url} loading='lazy'/>
                                 </ProductPRDetailImgContainer>)
                               }
                          </Slider>
                          </div>) : <ProductImgsContainer/> 
                        }
                    </ProductPRImgContainer>
                </ModalPRLeftContainer>

                <ModalPRRightContainer>
                    <ProductNameHeadContainer>
                        <ProductLeftPRName>{product.productName}</ProductLeftPRName>
                        <ProductCloseModal src={close} onClick={changeState}/>
                    </ProductNameHeadContainer>

                    <ProductPRDetailSinoText>Explore Sinogani: your unique shopping haven, where style meets emotion with each purchase.</ProductPRDetailSinoText>

                    <ProductPRDetailPrice>{currencyFormatter.format(quantity*product.price)}</ProductPRDetailPrice>

                    <ProductPRSizeContainer>
                        <ProducPRtDetailText>Sizes</ProducPRtDetailText>
                        <ProductSizesContentContainer>
                            {
                                product.sizes.map((size, index) => <ProductSize active={activeSize} onClick={() => handleSizeClick(size)} size={size} key={index} />) 
                            }
                        </ProductSizesContentContainer>
                    </ProductPRSizeContainer>

                    <ProducPRtDetailTexts>Quantity</ProducPRtDetailTexts>

                    <ProductPRDetailQuantityContainer>
                        <ProductPRDetailQuantityOption onClick={handleDecrementQuantity}>-</ProductPRDetailQuantityOption>
                        <ProductPRDetailQuantyText>{quantity}</ProductPRDetailQuantyText>
                        <ProductPRDetailQuantityOption onClick={handleIncrementQuantity}>+</ProductPRDetailQuantityOption>
                    </ProductPRDetailQuantityContainer>

                    <ProductAddContactContainer>
                        <ProductPRAddContainer onClick={handleAddToCart}>Add to cart</ProductPRAddContainer>

                        <ProductPRContactWhatContainer onClick={handleProductClickContact}> 
                            <ReactWhatsapp number='+86 187 1770 9574' message={message} className='react__product__pr__whatsapp'>Contact via whatsapp</ReactWhatsapp>
                        </ProductPRContactWhatContainer>
                    </ProductAddContactContainer>
                </ModalPRRightContainer>
            </ModalPRContentContainer>
        </ModalPR>
    </ModalPRContainer>
  )

}

export default ModalRecommandedProduct;