import React from 'react'
import { EuiComboBox, EuiFormRow } from "@elastic/eui";

const SubTypeUserField = ({
    label,
    options,
    onChange,
    selectedOptions,
    isClearable,
    placeholder,
    singleSelection = false,
    isInvalid,
    error,
  }) => {
     
    console.log(options)

  return (
    <EuiFormRow label={label} isInvalid={isInvalid} error={error}>
      <EuiComboBox
        options={options}
        onChange={onChange}
        selectedOptions={selectedOptions}
        singleSelection={singleSelection}
        placeholder={placeholder}
        isClearable={isClearable}
      />
    </EuiFormRow>
  )
}

export default SubTypeUserField
