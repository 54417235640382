import React from 'react';
import { useForm } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AddProductButton, AddProductContainer, AddProductContentContainer, AddProductHeadContentContainer, AddProductHeadLeftContainer, AddProductHeadRightContainer, AddProductSpinnerContainer, AddProductText, AddProductTitle,ProductImageAndBasicInfoContainer, RegisterBottomSpacing,  RegisterSpinner, UploadDataIcon, ProductBasicInformationContainer, ProductImageANdCategoryContainer, ProductImageContainer, ProductCategoryContainer, ProductPriceAndProductDateContainer, ProductPriceContainer, ProductProductDateContainer, ProductBasicInfoTextContainer, ProductBasicFormContainer, FormAddProductContainer, ProductImageContentContainer, ProductSpaceText, RadioButtonContainer, StyledForm, StyledLabel, StyledCheckedRadioInput, Span, SpanBefore, DivSpacer, ChooseImageContainers, AddIcons, AddTexts, ProductColorSizeAndVideoContainer, ProductSizeColorContainer, ProducVideoContainer, ChooseVideoContainers, ChooseIcons, ChooseTexts, SelectProductLabel, AddProductColorContainer, ChooseColorContainers, ChooseIcon, ChooseIconContainer, CloseIcon, ChooseText } from './AddProduct.element';
import { toast, Toaster } from 'react-hot-toast';
import AddProductInput from '../AddProductInput';
import AddProductDescInput from '../AddProductDescInput';
import DropdownSelectMenuWithSearch from '../DropdownSelectMenuWithSearch';
import { AfricaAllCountriesData, ChinaAllProvinceData, AfricaProductCategories, ChinaProductCategories, ProductAllSize} from '../../data/data';
import ImageSelected from '../ImageSelected';
import { useNavigate } from "react-router-dom";
import useAuths from '../../hooks/useAuths';
import moment from 'moment';
import DropdownSelectSizeMenuSearch from '../DropdownSelectSizeMenuSearch';
import ColorChoosing from '../ColorChoosing';
import SizeChoose from '../SizeChoose';
import axios from 'axios';
import useAddProduct from '../../hooks/useAddProduct';


const schema = yup.object({
  name: yup.string().required('Please enter the product name'),
  desc: yup.string().required('Please enter the product description'),
  price: yup.number().typeError('Please enter a valid number for the product unit price').required('Please enter the product unit price'),
  minOrder: yup.number().typeError('Please enter a valid number for the minimum order of product').required("Please enter the minimum order of the product"),
  contact: yup.string().min(8 , 'Checked your number entered!').required('Please enter your number!').matches(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,"Please enter a valid number!"),
});

const AddProduct = () => {
  useAuths();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);


  const sellerUid = params.get('q');
  const fileIcon = require('../../images/file.png');
  const close = require('../../images/close.png');

  const [user, setUser] = React.useState();

  const btnText = React.useRef(null);
  const btnSpinner = React.useRef(null);
  const btnImg = React.useRef(null);
  const formRef = React.useRef(null);
  const addProductRef = React.useRef(null);

  const icon = require('../../images/data-migration.png');

  const [isAfrica, setIsAfrica] = React.useState(true);
  const [countrySelected, setCountrySelected] = React.useState(null);
  const [categorySelected, setCategorySelected] = React.useState(null);
  const [imagesSelect, setImagesSelect] = React.useState([]);
  const [sizeSelect, setSizeSelect] = React.useState([]);
  const [active, setActive] = React.useState(false);
  const [colorSelect, setColorSelect] = React.useState([]);
  const [videoSelected, setVideoSelected] = React.useState(null);
  const [images, setImages] = React.useState([]);
  const [uid, setUid] = React.useState('');

  const { addProduct, loading } = useAddProduct();
  

  const { handleSubmit , register  , formState: {errors}} = useForm({
    resolver: yupResolver(schema),
  });

  const getUserList = async (email) => {

    const response = await axios.get('https://sinogani-api-server.onrender.com/auth/user', {
      params: {
        email: email
      }
    });

    setUser(response.data);

    return response.data;

  };

  const deleteImage = deleteIndex => {
    const nouvelleListeImages = images.filter((_, index) => index !== deleteIndex);
    const nouvelleListeImagesSelect = imagesSelect.filter((_, index) => index !== deleteIndex);
    setImages(nouvelleListeImages);
    setImagesSelect(nouvelleListeImagesSelect);
  };

  const deleteColor = deleteIndex => {
    const nouvelleListeColorSelect = colorSelect.filter((_, index) => index !== deleteIndex);
    setColorSelect(nouvelleListeColorSelect);
  }

  const deleteSize = deleteIndex => {
    const nouvelleListeColorSelect = sizeSelect.filter((_, index) => index !== deleteIndex);
    setSizeSelect(nouvelleListeColorSelect);
  }


  const handleChangeCountryState = country => setCountrySelected(country);
  const handleChangeCategoryState = category => setCategorySelected(category);
  const handleAddSizeSelected = size => setSizeSelect((prevSate) => [...prevSate, size]);
  const handleChangeActiveState = () => setActive(!active);


const formSubmit = async data => {
      

    await addProduct(data, isAfrica, countrySelected, categorySelected, images, videoSelected, user, sellerUid, sizeSelect, colorSelect);

    formRef.current.reset();
    setImages([]);
    setImagesSelect([]);
    setCountrySelected(null);
    window.location.reload();


}

const handleChangeColor = e => {
 setColorSelect((prevSate) => [...prevSate, e.target.value]);
}

const handleChooseImage = e => {
    for(let i = 0; i < e.target.files.length; i++){
        const newImage = e.target.files[i];
        newImage['id'] = Math.random();
        setImages((prevState) => [...prevState, newImage]);
        setImagesSelect((prevState) => [...prevState, URL.createObjectURL(newImage)]);
      }
}

  React.useEffect(() => {
    const fetchUserData = async () => {
      const uid = localStorage.getItem('uid');
      const email = localStorage.getItem('email');
      const token = localStorage.getItem('token');

  
      try {
        setUid(uid);
        const userData = await getUserList(email, token);
        setUser(userData);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchUserData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  React.useEffect(() => {
    window.scrollTo(0, 0);

    if(loading){
        btnSpinner.current.style.display = 'flex';
        btnText.current.textContent = 'Adding Product...';
        btnImg.current.style.display = 'none';
    }else{
      btnSpinner.current.style.display = 'none';
      btnText.current.textContent = 'Bulk upload Data';
      btnImg.current.style.display = 'flex';
    }

  }, [loading]);

  if(user) {

    if(user.sub){
      if (user.subStatus === true && moment(user.subEndStatusDate).isAfter(moment().format("L"))){
        console.log('yes')
      }else {
        navigate(`/user/${uid}`);
      }

    }else {
      navigate(`/user/${uid}`);
    }
    
  }

  const handleChooseVideo = e => {
    const file = e.target.files[0];
    const fileSize = file.size / (1024 * 1024); 
    const video = document.createElement('video');

    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = () => {
      const duration = video.duration;

      if (duration <= 60 && fileSize <= 100) {
        setVideoSelected(file);
        console.log('Vidéo sélectionnée :', file);
      } else {
        toast.error('Video exceeds maximum size or duration.Video exceeds maximum size or duration.')
        console.log('La vidéo dépasse la taille ou la durée maximale autorisée.');
      }
    };
  };

  const handleDeleteVideo = () => {
    setVideoSelected(null);
  };


  const customSort = (a, b) => {

    if (!isNaN(a.type) && isNaN(b.type)) {
      return 1;
    }
    
    else if (isNaN(a.type) && !isNaN(b.type)) {
      return -1;
    }
    
    else {
      return a.type.localeCompare(b.type);
    }
   };


    ProductAllSize.sort(customSort)


  return (
      <AddProductContainer>
        <Toaster toastOptions={{duration: 4000}} />
        <FormAddProductContainer ref={formRef} onSubmit={handleSubmit(formSubmit)}>
            <AddProductContentContainer>
                <AddProductHeadContentContainer>
                  <AddProductHeadLeftContainer>
                      <AddProductTitle>Add Product</AddProductTitle>
                      <AddProductText>Add your product for your customers</AddProductText>
                  </AddProductHeadLeftContainer>

                  <AddProductHeadRightContainer>
                      <AddProductButton onClick={handleSubmit(formSubmit)}>
                          <AddProductSpinnerContainer ref={btnSpinner}>
                            <RegisterSpinner size={25} />
                          </AddProductSpinnerContainer>

                          <UploadDataIcon ref={btnImg} src={icon} />

                        <span ref={btnText}>Bulk upload Data</span>

                      </AddProductButton>
                  </AddProductHeadRightContainer>
               </AddProductHeadContentContainer>

            <ProductImageAndBasicInfoContainer>
                  <ProductBasicInformationContainer>
                    <ProductBasicInfoTextContainer>Basic Information</ProductBasicInfoTextContainer>
                    
                    <ProductBasicFormContainer>
                       <AddProductInput id='name' label='Input Your Product Name' type='text' register={{...register('name')}} errorMessage={errors.name?.message} />
                       <AddProductDescInput id='desc' label='Input Your Descriptions Here' register={{...register('desc')}} errorMessage={errors.desc?.message} />
                    </ProductBasicFormContainer>

                  </ProductBasicInformationContainer>

                  <ProductImageANdCategoryContainer>
                    <ProductImageContainer>
                        <ProductBasicInfoTextContainer>Product Image</ProductBasicInfoTextContainer>

                        <ProductImageContentContainer>
                            
                            {
                              imagesSelect && imagesSelect.map((url, index) => <ImageSelected url={url} key={index} onDelete={() => deleteImage(index)}/>)
                            }
                           
                           <ChooseImageContainers ref={addProductRef}>
                              <input type='file' accept='image/*'  onChange={handleChooseImage} hidden/>
                                <AddIcons className="uil uil-plus"></AddIcons>
                                <AddTexts>Add Product</AddTexts>
                           </ChooseImageContainers>
                        </ProductImageContentContainer>
                    </ProductImageContainer>

                    <ProductCategoryContainer>
                        <ProductBasicInfoTextContainer>Product Space & Country Location</ProductBasicInfoTextContainer>

                        <ProductSpaceText>Product Space</ProductSpaceText>

                        <RadioButtonContainer>
                          <StyledForm>
                            <StyledLabel onClick={() => setIsAfrica(true)}>
                              <StyledCheckedRadioInput type='radio' id='africa' name='radio' defaultChecked/>
                              <Span>
                                <SpanBefore />
                                Africa Space
                              </Span>
                            </StyledLabel>

                            <StyledLabel onClick={() => setIsAfrica(false)}>
                              <StyledCheckedRadioInput type='radio' id='china' name='radio' />
                              <Span>
                                <SpanBefore />
                                China Space
                              </Span>
                            </StyledLabel>
                          </StyledForm>
                        </RadioButtonContainer>

                        <DropdownSelectMenuWithSearch data={isAfrica ? AfricaAllCountriesData : ChinaAllProvinceData} label={isAfrica ? 'Product Country' : 'Product Province'} selectType={isAfrica ? 'Select Product Country' : 'Select Product Province'} category={null} changeCountrySate={handleChangeCountryState} isLabel={true} isSearch={true} />
                    </ProductCategoryContainer>
                  </ProductImageANdCategoryContainer>
            </ProductImageAndBasicInfoContainer>

            <ProductPriceAndProductDateContainer>
              <ProductPriceContainer>
                  <ProductBasicInfoTextContainer>Price</ProductBasicInfoTextContainer>

                  <ProductBasicFormContainer>
                      <AddProductInput id='minOrder' label='Minimum Order' type='text' register={{...register('minOrder')}} errorMessage={errors.minOrder?.message} />
                      <AddProductInput id='price' label='Unit Price' type='text' register={{...register('price')}} errorMessage={errors.price?.message} />
                  </ProductBasicFormContainer>
              </ProductPriceContainer>

              <ProductProductDateContainer>
                  <ProductBasicInfoTextContainer>Category & Contact</ProductBasicInfoTextContainer>

                  <DropdownSelectMenuWithSearch data={isAfrica ? AfricaProductCategories : ChinaProductCategories} label='Product Category' selectType='Select Product Category' isSearch={false} isLabel={true} category={null} changeCategoryState={handleChangeCategoryState} />

                  <DivSpacer />

                  <AddProductInput id='contact' label='Your Contact' type='text' register={{...register('contact')}} errorMessage={errors.contact?.message} />

              </ProductProductDateContainer>
            </ProductPriceAndProductDateContainer>

            <ProductColorSizeAndVideoContainer>
               <ProductSizeColorContainer>
                  <ProductBasicInfoTextContainer>Product color & Size available</ProductBasicInfoTextContainer>

                   <SelectProductLabel>Add Product colors</SelectProductLabel>

                   <AddProductColorContainer>
                       {
                        colorSelect&&colorSelect.map((color, index) => <ColorChoosing bg={color} onDelete={() => deleteColor(index)} key={index}/>)
                       }     
                      <ChooseColorContainers onClick={() => {}}>
                          <input type='color' onChange={handleChangeColor}  hidden/>
                          <AddIcons className="uil uil-plus"></AddIcons>
                      </ChooseColorContainers>
                   </AddProductColorContainer>

                   <DivSpacer />           

                   <SelectProductLabel>Add Product Sizes</SelectProductLabel>

                   <AddProductColorContainer>
                      {
                        sizeSelect&&sizeSelect.map((size, index) => <SizeChoose key={index} size={size} onDelete={() => deleteSize(index)} />)
                      }
                      <ChooseColorContainers onClick={handleChangeActiveState}>
                          <AddIcons className="uil uil-plus"></AddIcons>
                      </ChooseColorContainers>
                   </AddProductColorContainer>

                   <DropdownSelectSizeMenuSearch data={ProductAllSize} changeCategoryState={handleAddSizeSelected} changeActiveState={handleChangeActiveState} isActive={active}  />


               </ProductSizeColorContainer>


               <ProducVideoContainer>
                  <ProductBasicInfoTextContainer>Product Video Commerce</ProductBasicInfoTextContainer>

                  <ChooseVideoContainers>
                  
                    {
                      videoSelected === null ? (
                        <>
                          <input type='file' accept='video/*'  onChange={handleChooseVideo} hidden/>
                          <ChooseIcons className="uil uil-plus"></ChooseIcons>
                          <ChooseTexts>Choose Video</ChooseTexts>
                        </>
                      ) : (
                        <>
                          <CloseIcon src={close} onClick={handleDeleteVideo}/>
                          <ChooseIconContainer>
                              <ChooseIcon src={fileIcon} />
                              <ChooseText>{videoSelected.name}</ChooseText>
                          </ChooseIconContainer>
                        </>
                      )
                    }
                  </ChooseVideoContainers>

               </ProducVideoContainer>
            </ProductColorSizeAndVideoContainer>

            <RegisterBottomSpacing />
          </AddProductContentContainer>
        </FormAddProductContainer>
      </AddProductContainer>
  )
}

export default AddProduct
