import styled from "styled-components";

export const EmptyProductContainer = styled.div`
margin-top: 100px;
margin-bottom: 228px;
display: flex;
width: 100%;
flex-direction: column;
align-items: center;
justify-content: center;
overflow: hidden;
`;

export const EmptyImg = styled.img`
width: 200px;
height: 200px;
margin-bottom: .3rem;
object-fit: cover;
`;

export const EmptyProductText = styled.h1`
width: 500px;
font-style: normal;
font-weight: 500;
font-size: 16px;
text-align: center;
line-height: 30px;
color: #000000;
`