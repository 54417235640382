import styled from "styled-components";

export const PaginationContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
margin-top: 1.5rem;
margin-bottom: 1.5rem;
`;

export const PaginationPreviousButton = styled.div`
box-sizing: border-box;
height: 40px;
border: 2px solid #DBDBDB !important;
display: ${({disabled}) => (disabled ? 'none' : 'flex')};
border-radius: .7rem;
align-items: center;
justify-content: center;
cursor: pointer;
width: 90px;
font-style: normal;
font-weight: 500;
outline: none !important;
color: #000;
font-size: 14px;
line-height: 17px;
color: #000000;
`;

export const PaginationNextButton = styled.div`
box-sizing: border-box;
height: 40px;
border: 2px solid #DBDBDB !important;
display: ${({disabled}) => (disabled ? 'none' : 'flex')};
border-radius: .7rem;
align-items: center;
justify-content: center;
cursor: pointer;
margin-left: auto;
width: 70px;
font-style: normal;
font-weight: 500;
outline: none !important;
color: #000;
font-size: 14px;
line-height: 17px;
color: #000000;
`;

export const PaginationPageIndicatorContainer = styled.div`
margin-left: auto;
margin-right: auto;
display: flex;
flex-direction: row;
`;

export const PaginationPageNumber = styled.div`
width: 40px;
height: 40px;
align-items: center;
justify-content: center;
cursor: pointer;
display: flex;
margin-left: 8px;
margin-right: 8px;
font-style: normal;
font-weight: 500;
outline: none !important;
color: #303032;
font-size: 1rem;
line-height: 17px;
`;