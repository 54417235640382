import styled from "styled-components";

export const ColorsChoosingContainer = styled.div`
width: 50px;
height: 50px;
margin-right: 10px;
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const ColorsChoosingContainers = styled.div`
width: 50px;
height: 50px;
margin-right: 10px;
cursor: pointer;
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const ColorsChoosing = styled.div`
width: 100%;
height: 100%;
border-radius: .5rem;
`;

export const CheckColorContainer = styled.div`
position: absolute;
top: -17%;
right: -7%;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
cursor: pointer;
z-index: 2;

&:hover{
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}
`;

export const CheckColorContainers = styled.div`
position: absolute;
top: -17%;
right: -7%;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
cursor: pointer;
display: ${({select}) => (select ? 'flex' : 'none')};
z-index: 2;

&:hover{
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}
`;

export const CheckColorSub = styled.div`
height: 20px;
width: 20px;
background-color: #fff;
border-radius: 50%;
border: 1.5px solid #dbdbdb!important;
display: flex;
align-items: center;
justify-content: center;
`;

export const CheckColorImage = styled.img`
height: 14px;
width: 14px;
padding: 2px;
align-self: center;
`;

export const CheckColorImages = styled.img`
height: 18px;
width: 18px;
padding: 2px;
align-self: center;
`;