export const convertDate = () => {

    const date = new Date();

    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear();

    return `${month < 10 ? '0' : ''}${month}/${day < 10 ? '0' : ''}${day}/${year}`;

};

export const SubTypeData = [

    {
        id: 'standard',
        value: 'STANDARD',

    },
    {
        id: 'vip',
        value: 'VIP',

    },
    {
        id: 'gold',
        value: 'GOLD',

    },
    {
        id: 'prenuim',
        value: 'PRENUIM',

    },

];

export const VipInfoData = [

    {
        id: 1,
        text: "The VIP membership is your unique opportunity to promote and sell products on the Sino African markets. One year free products display on the E-commerce space.",
    },
    {
        id: 2,
        text: "Free invitations letters opportunities to attend forums , exhibitions in China and in African countries Access to Shanghai GANI multiples activities and events",
    },
    {
        id: 3,
        text: "Discounts on the accommodations and transportations to the events whether in China or in African Countries.",
    },
    {
        id: 4,
        text: "Free adverts on the platform",
    },
    {
        id: 5,
        text: "Networking opportunities with Governments officials, investors, ..",
    },
];


export const GoldInfoData = [

    {
        id: 1,
        text: "Opportunities for free invitations letters to China or to African countries.",
    },
    {
        id: 2,
        text: "Products quality quantity control",
    },
    {
        id: 3,
        text: "Services for Products research (sourcing, purchasing and supply)",
    },
    {
        id: 4,
        text: "Services for Market investigations (discover new products, and new market)",
    },
    {
        id: 5,
        text: "Services assistance and procedures for purchasing from Chinese factories (investigation and confirm the credibility of factories)",
    },
    {
        id: 6,
        text: "Services assistances and procedures for purchasing from African countries factories (investigation and confirm the credibility of factories)",
    },
    {
        id: 7,
        text: "Assistance and procedures for products control (quantity and quality)",
    },
    {
        id: 8,
        text: "Assistance and procedures for logistics and products delivery",
    },

]
export const STANDARDInfoData = [

    {
        id: 1,
        text: "Free factories and markets investigations for pro forma invoices",
    },
    {
        id: 2,
        text: "5%-10% discounts on factories purchasing",
    },
    {
        id: 3,
        text: " Access to the latest Business opportunities between China and African countries( find investors, sponsors, partners, tenders )",
    },

]

export const PrenuimInfoData = [

    {
        id: 1,
        text: "Available directory services of over 3000 companies and factories in African countries, all industries (Agricultural, Mining, Foods and beverage, Arts,.)",
    },
    {
        id: 2,
        text: "Publication of available Job offers from employers",
    },
    {
        id: 3,
        text: "Access to exclusive Events",
    },
    {
        id: 4,
        text: "Services for increasing visibility via the platforms www.shanghaigani.com www.sinogani.com",
    },

]