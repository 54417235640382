import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";


const useAuth = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    const uid = localStorage.getItem('uid');
    if(!token){
      navigate("/signin");
    }else {
      (async () => {
        try {
          const response = await axios.get('https://sinogani-api-server.onrender.com/auth/check', {
              params: {
                email: email,
                uid: uid
              }
          });
          navigate(response.data.redirect);
        } catch (error) {
          console.error("Error during API call:", error);
        }
      })();
    }

  }, [navigate]);
};

export default useAuth;
