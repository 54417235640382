import React, { useState } from "react";
import Header from "../Header";
import { EuiFlexGroup, EuiForm, EuiSpacer } from "@elastic/eui";
import MeetingUsersField from "../FormComponents/MeetingUsersField";
import useAuths from "../../hooks/useAuths";
import userfetchUsers from "../../hooks/userfetchUsers";
import moment from "moment";
import MeetingDateField from "../FormComponents/MeetingDateField";
import CreateMeetingButtons from "../FormComponents/CreateMeetingButtons";
import SubTypeUserField from "../FormComponents/SubTypeUserField";
import { useNavigate } from "react-router-dom";
import { id } from "./AdminPage";
import UseToats from "../../hooks/UseToats";
import useCreateSubTypeOfUser from "../../hooks/useCreateSubTypeOfUser";

const CreateSubType = () => {
  const navigate = useNavigate();
    useAuths();
    const [createToast] = UseToats();
    const [users] = userfetchUsers();
    console.log(users)
    const subType = [{ 
      label: 'STANDARD',
      value: 'STANDARD',
    },
    {
      label: 'VIP',
      value: 'VIP'
    },
    {
      label: 'GOLD',
      value: 'GOLD',
    },
    {
      label: 'PRENUIM',
      value: 'PRENUIM',
    },
    ];
  
    const [selectUsers, setSelectUsers] = React.useState([]);
    const [selectSubType, setSelectSubType] = React.useState([]);
    const [startDate, setStartDate] = React.useState(moment());
    const [endDate, setEndDate] = React.useState(moment());
    const [showErrors, setShowErrors ] = useState({
        subType: {
          show: false,
          message: [],
        },
        selectUser: {
          show: false,
          message: [],
        }});

    const onUserChange = (selectedOptions) => {
        setSelectUsers(selectedOptions);
      };

    const onSubTypeChange = (selectedOptions) => {
        setSelectSubType(selectedOptions);
    };

    const { createSubTypeOfUser, error } = useCreateSubTypeOfUser();


      const validateFrom = () => {
        let errors = false;
        const clonedShowErrors = {...showErrors}
    
        if (!selectSubType.length){
          clonedShowErrors.subType.show = true;
          clonedShowErrors.subType.message = ["Please Select a subcription type"];
        } else {
          clonedShowErrors.subType.show = false;
          clonedShowErrors.subType.message = [];
        }

        if (!selectUsers.length){
          clonedShowErrors.selectUser.show = true;
          clonedShowErrors.selectUser.message = ["Please Select a user mail"];
        } else {
          clonedShowErrors.selectUser.show = false;
          clonedShowErrors.selectUser.message = [];
        }
    
        setShowErrors(clonedShowErrors);
        return errors;
      };

      const handleCreateSubTypeUser = async () => {

        if (!validateFrom()){

          await createSubTypeOfUser(selectUsers, selectSubType, startDate, endDate, navigate, id, createToast);

        }

      }
      
  
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: "#F7F8FC"
      }}
    >
      <Header />

      <EuiFlexGroup justifyContent="center" alignItems="center">
        <EuiForm>

        <SubTypeUserField
            label="Select SubType"
            options={subType}
            onChange={onSubTypeChange}
            selectedOptions={selectSubType}
            singleSelection={{ asPlainText: true }}
            isClearable={false}
            placeholder="Select a subcription"
            isInvalid={showErrors.subType.show}
            error={showErrors.subType.message}
          />   
          
          <MeetingUsersField
            label="Select User"
            options={users}
            onChange={onUserChange}
            selectedOptions={selectUsers}
            singleSelection={{ asPlainText: true }}
            isClearable={false}
            placeholder="Select a user by email"
            isInvalid={showErrors.selectUser.show}
            error={showErrors.selectUser.message}
          />
          <MeetingDateField selected={startDate} setStartDate={setStartDate} />
          <MeetingDateField selected={endDate} setStartDate={setEndDate} />
          <EuiSpacer />
          <CreateMeetingButtons isEdit={false} closeFlyout={undefined} createMeeting={handleCreateSubTypeUser}/>
        </EuiForm>
      </EuiFlexGroup>
    </div>
  )
}

export default CreateSubType
